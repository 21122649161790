import moment from 'moment';
import React from 'react';
import memoize from 'memoize-one';
import EventService from '../../services/eventService';
import CourseService from '../../services/courseService';
import ActorTable from './actorTable';
import ActorChart from './actorChart';
import Loading from 'react-loading';
import { Link } from 'react-router-dom';
import ImgBtn from '../opionBtn/exportImage';
import CSVBtn from '../opionBtn/exportCSV';
import LinkBtn from '../opionBtn/exportLink';
import { LoginContext } from '../../loginContext';
import Select from 'react-select';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import StudentInfo from '../../utils/StudentInfo';;

interface OProps { }
interface OState {
    courseList: any[];
    courseId: string;
    totalAgent: number;
    eventUserList: any[];
    eventDateList: any[];
    courseInfo: any;
    eventList: any[];
    sdate: string;
    edate: string;
    sDateDisabled: boolean;
    eDateDisabled: boolean;
    periodDate: string;
}

const columns1 = memoize((handleAction) => [
    {
        name: "學生姓名",
        selector: "name",
        sortable: true,
        width: "15%",
        center: true
    },
    {
        name: "學生背景",
        cell: (row: any) => StudentInfo(row._id.substring(7)),
        sortable: true,
        width: "20%",
        center: true
    },
    {
        name: "學習紀錄數量	",
        selector: "event",
        sortable: true,
        width: "20%",
        center: true
    },
    {
        name: "註冊課程	",
        selector: "register",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "完課課程	",
        selector: "finish",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "完課比例	",
        selector: "finishRate",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "詳細內容",
        cell: (row: any) => <button className="genric-btn white-border" onClick={() => { handleAction(row) }}>檢視</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true,
        compact: true,
        button: true,
        width: "10%"
    }
]);

const columns2 = [
    {
        name: "",
        selector: "statement",
        width: "100%",
        cell: (row: any) => {
            return (
                <p><strong>{row.statement.actor.name}</strong> {row.statement.verb.display['zh-TW']} {(row.statement.object.definition?.name !== undefined) ? row.statement.object.definition.name['zh-TW'] : row.statement.object.id} <span className="text-primary">{(row.statement.result?.score !== undefined) ? `成績${row.statement.result.score.raw}` : ""}{(row.statement.result?.duration !== undefined) ? ` 花費了${moment().startOf('day').second(moment.duration(row.statement.result.duration).asSeconds()).format('HH:mm:ss')}` : ""} </span> {moment(row.statement.timestamp).format('YYYY-MM-DD HH:mm:ss')}</p>
            )
        }

    }
];

class ActorList extends React.Component<OProps, OState>{
    private eventService: EventService;
    private courseService: CourseService;
    private sdate: string;
    private edate: string;
    private org: string;
    private account: string;
    private role: string;
    private date:string;
    static contextType = LoginContext;

    constructor(props: any) {
        super(props);
        this.eventService = new EventService();
        this.courseService = new CourseService();
        this.org = "";
        this.account = "";
        this.role = "";
        this.date = "";
        this.state = {
            courseList: [-1],
            courseId: '',
            courseInfo: {
                totalRegisterPeople: -1,
                totalFinishPeople: -1,
                avgFinishRate: "-1"
            },
            eventUserList: [-1],
            eventList: [-1],
            eventDateList: [-1],
            totalAgent: -1,
            sdate: '2023-02-01',//moment().subtract(14, 'day').format('YYYY-MM-DD'),
            edate: '2023-07-31',//moment().format('YYYY-MM-DD')
            sDateDisabled: true,
            eDateDisabled: true,
            periodDate: '2023-02-01/2023-07-31'
        }
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
    }

    componentDidMount() {
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account)
        this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
        this.getFullCourses(this.date, this.org, this.account, this.role);
        //this.getAllCoursefinish(this.org, this.account, this.role);
        this.getCoursefinish(this.date, this.org, this.account, this.role, this.state.courseId);
        this.getEventList(this.date, this.org, this.account, this.role, this.state.courseId);
        //this.getEventTextAgent(this.date, this.org, this.account, this.role, this.state.courseId);
        this.getEventByDate(this.date, this.org, this.account, this.role, this.state.courseId);
        this.getEventByUser(this.date, this.org, this.account, this.role, this.state.courseId);
    }

    resetState = () => {
        this.setState({
            courseId: '',
            courseInfo: {
                totalRegisterPeople: -1,
                totalFinishPeople: -1,
                avgFinishRate: "-1"
            },
            eventUserList: [-1],
            eventList: [-1],
            eventDateList: [-1],
            totalAgent: -1,
        })
    }

    getEventList = async (date: string, org: string, filter: string, role: string, courseId?: string): Promise<any> => {
        let eventList = await this.eventService.getEventList(date, org, "", "", courseId? courseId: "", filter, role, courseId);
        this.setState({ eventList: eventList });
    }

    getEventTextAgent = async (date: string, org: string, filter: string, role: string, courseId?: string): Promise<any> => {
        let eventTextAgent = await this.eventService.getEventTextAgent(date, org, filter, role, courseId);
        this.setState({ totalAgent: eventTextAgent.length });
    }

    getAllCoursefinish = async (org: string, filter: string, role: string) => {
        let courseInfo: any = await this.courseService.getAllCoursefinish(org, filter, role);
        this.setState({ courseInfo: courseInfo.courseInfo });
        let courseSelectValues = courseInfo.courseList.map((course: any) => {
            return {
                label: course.name,
                value: course._id
            }
        });
        this.setState({ courseList: courseSelectValues });
    }

    getFullCourses = async (date: string, org: string, filter: string, role: string) => {
        let fullCourses: any = await this.courseService.getFullCourses(date, org, filter, role);
        let courseSelectValues = fullCourses.fullCourseList.map((course: any) => {
            return {
                label: course.courseName,
                value: "https://moocs.chihlee.edu.tw/info/"+course.courseId
            }
        });
        this.setState({ courseList: courseSelectValues });
    }

    getCoursefinish = async (date: string, org: string, filter: string, role: string, courseId?: string) => {
        let courseInfo: any = await this.courseService.getCoursefinish(date, org, filter, role, courseId);
        this.setState({ courseInfo: courseInfo.courseInfo });
    }

    getEventByDate = async (date: string, org: string, filter: string, role: string, courseId?: string): Promise<any> => {
        let eventDateList = await this.eventService.getEventByDate(date, org, filter, role, courseId);
        let eventList = await this.settingDailyData(eventDateList)
        this.setState({ eventDateList: eventList });
    }

    getEventByUser = async (date: string, org: string, filter: string, role: string, courseId?: string): Promise<any> => {
        let eventUserList = await this.eventService.getEventByUser(date, org, filter, role, courseId);
        this.setState({ eventUserList: eventUserList });
        this.setState({ totalAgent: eventUserList.length });
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    viewContent = (data: any) => {
        let sdate = this.state.sdate;
        let edate = this.state.edate;
        window.location.href = `/lrs/actor/content?id=${window.btoa(data._id)}&sdate=${sdate}&edate=${edate}`
    }

    roleType = () => {
        if (this.role === "admin" || this.role === "owner")
            return true;
        else
            return false;
    }

    refresh = () => {
        let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
        this.setState({ eventDateList: [-1] })
        this.getEventByDate(date, this.org, this.account, this.role, this.state.courseId);
    }

    submitDateRange = () => {
        if (moment(this.state.edate).diff(this.state.sdate) < 0) {
            alert("結束日期需大於起始日期")
        } else if (moment(this.state.edate).diff(this.state.sdate, 'days') > 200) {
            alert("日期區間請在六個月內")
        } else {
            this.resetState();
            this.sdate = this.state.sdate;
            this.edate = this.state.edate;
            this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
            this.getCoursefinish(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getEventList(this.date, this.org, this.account, this.role, this.state.courseId);
            //this.getEventTextAgent(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getEventByDate(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getEventByUser(this.date, this.org, this.account, this.role, this.state.courseId);
        }
    }

    renderLoading() {
        return <Loading type={"spin"} color={"#FFFFFF"} height={'20%'} width={'20%'}></Loading>
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingDailyData = (ary: any[]) => {
        let userAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false
            let index = 0
            let userData = {
                x: dateAry[i],
                y: 0
            }

            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                }
            }

            if (hasValue) {
                userData.y = Math.floor(ary[index].users)
                userAry.push(userData);
            }
            else {
                userAry.push(userData);
            }
        }
        return userAry;
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    handleCourseChange = (e: any) => {
        if (e === null)
            this.setState({ courseId: '' })
        else
            this.setState({ courseId: e.value })
    }

    periodDateHandler = (e: any) => {
        const targetPeriod = e.target.value;
        if(targetPeriod){
            const periodStartDate = targetPeriod.split('/')[0];
            const periodEndDate = targetPeriod.split('/')[1];
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: true,
                eDateDisabled: true,
                sdate: periodStartDate,
                edate: periodEndDate
            });
        }else{
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: false,
                eDateDisabled: false
            });
        }
    }

    render() {
        return (
            <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <ul id="title-spacer" className="breadcrumbs">
                            <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >學習行為分析</span></Link>/</li>
                            <li><span>學習者紀錄</span></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 py-1">
                        <h5>課程</h5>
                        <Select options={this.state.courseList} onChange={this.handleCourseChange} isClearable={true} />
                    </div>
                    <div className="col-md-3 py-1">
                        <h5>學期</h5>
                        <Form.Select value={this.state.periodDate} onChange={this.periodDateHandler}>
                            <option value="">自訂</option>
                            <option value="2023-02-01/2023-07-31">111-第二學期</option>
                            <option value="2022-08-01/2023-01-31">111-第一學期</option>
                            <option value="2022-02-01/2022-07-31">110-第二學期</option>
                            <option value="2021-08-01/2022-01-31">110-第一學期</option>
                            <option value="2021-02-01/2021-07-31">109-第二學期</option>
                            <option value="2020-08-01/2021-01-31">109-第一學期</option>
                        </Form.Select>
                    </div>
                    <div className="col-md-5 py-1">
                        <h5>時間</h5>
                        <div className="row">
                            <div className="col-auto ms-2 py-1">
                                <div className="input-group">
                                    <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} disabled={this.state.sDateDisabled} />
                                </div>
                            </div>
                            <div className="col-auto ms-2 py-2 m-none lh-lg">~</div>
                            <div className="col-auto ms-2 py-1">
                                <div className="input-group">
                                    <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} disabled={this.state.eDateDisabled} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 py-1">
                        <h5>動作</h5>
                        <button className="btn btn-secondary" onClick={this.submitDateRange}>篩選</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2 py-1">
                        <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                            <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                        <Popover>
                                            <Popover.Header as="h3">學生</Popover.Header>
                                            <Popover.Body>
                                            在篩選條件下，有任何學習行為的不重複的學生數量
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <h2>{(this.state.totalAgent === -1) ? this.renderLoading() : this.state.totalAgent}</h2>
                            <h5>學生</h5>
                        </div>
                    </div>
                    <div className="col-md-2 py-1">
                        <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                            <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                        <Popover>
                                            <Popover.Header as="h3">註冊課程</Popover.Header>
                                            <Popover.Body>
                                            在篩選條件下，所有註冊課程的學生數量 (如果同一個學生註冊了 2 個課程，數量將加 2)
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <h2>{(this.state.courseInfo.totalRegisterPeople === -1) ? this.renderLoading() : this.state.courseInfo.totalRegisterPeople}</h2>
                            <h5>註冊課程</h5>
                        </div>
                    </div>
                    <div className="col-md-2 py-1">
                        <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                            <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                        <Popover>
                                            <Popover.Header as="h3">完課課程</Popover.Header>
                                            <Popover.Body>
                                            在篩選條件下，所有完成課程的學生數量 (如果同一個學生完成了 2 個課程，數量將加 2)
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <h2>{(this.state.courseInfo.totalFinishPeople === -1) ? this.renderLoading() : this.state.courseInfo.totalFinishPeople}</h2>
                            <h5>完課課程</h5>
                        </div>
                    </div>
                    <div className="col-md-2 py-1">
                        <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                            <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                        <Popover>
                                            <Popover.Header as="h3">平均完課比例</Popover.Header>
                                            <Popover.Body>
                                            在篩選條件下，所有完成課程的學生數量占所有註冊課程的學生數量的比例
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <h2>{(this.state.courseInfo.avgFinishRate === "-1") ? this.renderLoading() : this.state.courseInfo.avgFinishRate}</h2>
                            <h5>平均完課比例</h5>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>學習活躍度</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">學習活躍度</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，每一天的有進行學習的不重複的學生數量(<a href="https://leaverij.gitbook.io/visca/tech/recipes" target="_blank" rel="noreferrer">所有學習行為說明頁面</a>)
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/actor/list/${this.date}/${this.org}` : `/embed/actor/list/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li>                                              <li><button className="dropdown-item" onClick={this.refresh}>重新整理</button></li>
                                            <li><CSVBtn data={this.state.eventDateList} /></li>
                                            <li><ImgBtn id={0} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <ActorChart
                                tickFormat={this.tickFormat}
                                labelx={"日期"}
                                labely={"人數"}
                                data={this.state.eventDateList}
                                datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} users`}
                                chartType={"line"} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>學生</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">學生</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，有進行學習的學生的紀錄清單
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        {/*
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            功能
                                        </button>
                                        */}
                                    </div>
                                </div>
                            </div>
                            {this.state.eventUserList[0] === -1 ? < ActorTable columns={columns1(this.viewContent)} loading={true} /> : <ActorTable columns={columns1(this.viewContent)} data={this.state.eventUserList} />}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>學習紀錄</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">學習紀錄</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，所有學生的學習行為紀錄
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        {/*
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            功能
                                        </button>
                                        */}
                                    </div>
                                </div>
                            </div>
                            {this.state.eventList[0] === -1 ? < ActorTable columns={columns2} loading={true} /> : <ActorTable columns={columns2} data={this.state.eventList} />}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
export default ActorList;