import React from 'react';
import { Link } from 'react-router-dom';
import VideoWatchGrid from './videoWatchGrid';
import VideoChart from './videochart';
import VideoTable from './videoTables';
import VideoService from '../../services/videoService';
import moment from 'moment';
import ImgBtn from '../opionBtn/exportImage';
import CSVBtn from '../opionBtn/exportCSV';
import LinkBtn from '../opionBtn/exportLink';
import { LoginContext } from '../../loginContext';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface VCProps { }
interface VCState {
    videoInfo: any;
    videoEvent: any[];
    videoDuration: any[];
    timesAry: any[];
    hourAry: any[];
    sdate: string;
    edate: string;
    anayType: string;
    totalWatchFinish: number;
    avgWatchFinish: string;
    totalWatchPeopleInDateRange: number;
    typeText: string;
}

const columns = [
    {
        name: "姓名",
        selector: "name",
        sortable: true,
        width: "30%",
    },
    {
        name: "最後觀看時間",
        selector: "lastWatchTime",
        sortable: true,
        width: "70%",
        cell: (row: any) => {
            return (
                <li>
                    <span className="text-gray">{row.lastWatchTime.date}</span>
                    <p>from {row.lastWatchTime.start} to {row.lastWatchTime.end}</p>
                </li>
            )
        }
    }
];

const columns1 = [
    {
        name: "姓名",
        selector: "name",
        sortable: true,
        width: "50%",
    },
    {
        name: "觀看次數",
        selector: "watchTimes",
        sortable: true,
        width: "50%"
    }
];

const columns2 = [
    {
        name: "姓名",
        selector: "name",
        sortable: true,
        width: "50%",
    },
    {
        name: "觀看時數",
        selector: "watchHours",
        sortable: true,
        width: "50%"

    }
];

class VideoContent extends React.Component<VCProps, VCState>{
    private videoService: VideoService;
    private videoId: string;
    private videoName: string;
    private courseName: string;
    private durarion: string;
    private first: boolean;
    private sdate: string;
    private edate: string;
    private videoSrc: string;
    private org: string;
    private account: string;
    private role: string;
    private date:string;
    static contextType = LoginContext;

    constructor(props: VCProps) {
        super(props);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.videoId = decodeURIComponent(urlParams.get("id") || "");
        this.videoService = new VideoService();
        this.videoName = "";
        this.courseName = "";
        this.durarion = "";
        this.first = true;
        this.org = "";
        this.account = "";
        this.role = "";
        this.videoSrc = this.videoSource(this.videoId);
        this.state = {
            videoInfo: {
                name: "",
                courseName: "",
                durarion: "00:00:00",
                totalWatchHours: "-1",
                avgWatchHour: "-1",
                totalWatchPeople: -1,
                avgWatchPeople: -1,
                totalWatchTimes: -1,
                avgWatchTimes: -1,
                avgWatchHourPerTimes: "00:00:00",
                avgWatchHourV: "00:00:00",
                durarionAsSec: -1,
            },
            totalWatchFinish: -1,
            avgWatchFinish: "0%",
            videoEvent: [-1],
            timesAry: [-1],
            hourAry: [-1],
            videoDuration: [-1],
            sdate: moment(urlParams.get("sdate"), 'YYYY-MM-DD', true).isValid() ? urlParams.get("sdate") || "" : moment().subtract(7, 'day').format('YYYY-MM-DD'),
            edate: moment(urlParams.get("edate"), 'YYYY-MM-DD', true).isValid() ? urlParams.get("edate") || "" : moment().format('YYYY-MM-DD'),
            anayType: "timesct",
            totalWatchPeopleInDateRange: 0,
            typeText: "觀看次數"
        }
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
        this.date = "";
    }

    componentDidMount() {
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account)
        this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
        this.getSingleVideo(this.date, this.videoId, this.org, this.account, this.role);
        this.getSingleVideoByDay(this.date, this.videoId, this.org, this.account, this.role);
        this.getSingleVideoEvent(this.date, this.videoId, this.org, this.account, this.role);
        this.getSingleVideoFinish(this.date, this.videoId, this.org, this.account, this.role);
        this.getTotalWatchPeople(this.date, this.org, this.account, this.role);
    }

    resetState = () => {
        this.setState({
            videoInfo: {
                totalWatchHours: "-1",
                avgWatchHour: "-1",
                totalWatchPeople: -1,
                totalWatchTimes: -1,
                avgWatchTimes: -1,
            },
            totalWatchFinish: -1,
            avgWatchFinish: "0%",
            videoEvent: [-1],
            timesAry: [-1],
            hourAry: [-1],
            videoDuration: [-1]
        })
    }

    getSingleVideo = async (sdtae: string, videoId: string, org: string, filter: string, role: string) => {
        let video = await this.videoService.getSingleVideo(sdtae, videoId, org, filter, role);
        let videoList = await this.videoService.getSingleVideoEvent(sdtae, videoId, org, filter, role);
        if (this.first) {
            this.videoName = video.name;
            this.courseName = video.courseName;
            this.durarion = video.durarion;
            this.first = false;
        }
        video.avgWatchTimes = parseFloat((video.avgWatchTimes).toFixed(2));
        this.setState({ videoInfo: video });
        let data = this.calculateTimesInDuration(videoList, video.durarionAsSec);
        this.setState({ videoDuration: data })
    }

    getSingleVideoByDay = async (sdtae: string, videoId: string, org: string, filter: string, role: string) => {
        let videoList = await this.videoService.getSingleVideoByDay(sdtae, videoId, org, filter, role);
        let [timesAry, hoursAry] = this.settingDailyData(videoList)
        this.setState({ timesAry: timesAry, hourAry: hoursAry });
    }

    getSingleVideoFinish = async (sdtae: string, videoId: string, org: string, filter: string, role: string) => {
        let video = await this.videoService.getSingleVideoFinish(sdtae, videoId, org, filter, role);
        this.setState({ totalWatchFinish: video.totalWatchFinish, avgWatchFinish: video.avgWatchFinish });

    }

    getSingleVideoEvent = async (sdtae: string, videoId: string, org: string, filter: string, role: string) => {
        let videoList = await this.videoService.getSingleVideoEvent(sdtae, videoId, org, filter, role);
        this.setState({ videoEvent: videoList })
    }

    getTotalWatchPeople = async (sdate: string, org: string, filter: string, role: string) => {
        let data = await this.videoService.getTotalWatchPeople(sdate, org, filter, role);
        this.setState({ totalWatchPeopleInDateRange: data.totalWatchPeople });
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    changeAnalysis = (type: any, text: string) => {
        this.setState({ typeText: text })
        this.setState({ anayType: type });
    }

    refresh = (id: number) => {
        let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
        switch (id) {
            case 0: {
                this.setState({ timesAry: [-1], hourAry: [-1] });
                this.getSingleVideoByDay(date, this.videoId, this.org, this.account, this.role);
                break;
            }
            case 1: {
                this.setState({ videoDuration: [-1] });
                this.getSingleVideo(date, this.videoId, this.org, this.account, this.role);
                break;
            }
        }
    }

    submitDateRange = () => {
        if (moment(this.state.edate).diff(this.state.sdate) < 0) {
            alert("結束日期需大於起始日期")
        } else if (moment(this.state.edate).diff(this.state.sdate, 'days') > 200) {
            alert("日期區間請在六個月內")
        } else {
            this.resetState();
            this.sdate = this.state.sdate;
            this.edate = this.state.edate;
            this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
            this.getSingleVideo(this.date, this.videoId, this.org, this.account, this.role);
            this.getSingleVideoByDay(this.date, this.videoId, this.org, this.account, this.role);
            this.getSingleVideoEvent(this.date, this.videoId, this.org, this.account, this.role);
            this.getSingleVideoFinish(this.date, this.videoId, this.org, this.account, this.role);
            this.getTotalWatchPeople(this.date, this.org, this.account, this.role);
        }
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingDailyData = (ary: any[]) => {
        let timesAry = [], hoursAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false;
            let index = 0;
            let hourData = {
                x: dateAry[i],
                y: 0
            }
            let timesData = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                }
            }
            if (hasValue) {
                hourData.y = parseFloat((ary[index].watchHours / 60).toFixed(2));//Math.floor(ary[index].watchHours / 60);
                timesData.y = ary[index].watchTimes
                timesAry.push(timesData);
                hoursAry.push(hourData);
            }
            else {
                timesAry.push(timesData);
                hoursAry.push(hourData);
            }
        }
        return [timesAry, hoursAry];
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    analysisType = () => {
        if (this.state.anayType === "timesct") {
            return (
                <VideoChart
                    labelx={"日期"}
                    labely={"次數"}
                    data={this.state.timesAry}
                    tickFormat={this.tickFormat}
                    datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nwatch ${datum.y} times`}
                />
            )
        } else {
            return (
                <VideoChart
                    labelx={"日期"}
                    labely={"分鐘"}
                    data={this.state.hourAry}
                    tickFormat={this.tickFormat}
                    datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nwatch ${datum.y} mins`}
                />
            )
        }
    }

    roleType = () => {
        if (this.role === "admin" || this.role === "owner")
            return true;
        else
            return false;
    }

    calculateTimesInDuration = (videoData: any[], durarionAsSec: number) => {
        let dataArray = [];
        for (let i = 0; i <= durarionAsSec; i++) {
            let data = {
                x: moment().startOf('day').seconds(i).format('HH:mm:ss'),
                y: 0
            }
            dataArray.push(data);
        }
        if (durarionAsSec >= 0) {
            for (let i = 0; i < videoData.length; i++) {
                for (let j = Math.floor(videoData[i].start); j < Math.floor(videoData[i].end); j++) {
                    dataArray[j].y++;
                }
            }
        }
        return dataArray;
    }

    tickFormatDur = (x: any) => {
        if (x === "00:00:00" || x === this.state.videoInfo.durarion)
            return x;
    }

    videoSource = (videoId: any) => {
        let videoSrc = decodeURIComponent(videoId);
        if (videoSrc.search('youtube') !== -1) {
            let url = new URL(videoSrc);
            videoSrc = url.protocol + '//' + url.hostname + '/embed/' + url.searchParams.get("v")
        }
        return videoSrc
    }

    render() {
        return (
            <>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >學習內容分析</span></Link>/</li>
                                <li><Link to="/lrs/video" style={{ textDecoration: "none", color: "#000000" }}><span>影片學習分析</span></Link>/</li>
                                <li><span>{this.videoName}</span></li>
                            </ul>
                        </div>
                        <div className="d-md-flex justify-content-md-end py-2">
                            <div className="col-auto ms-2 py-1">
                                <div className="input-group">
                                    <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} />
                                </div>
                            </div>
                            <div className="col-auto ms-2 py-2 m-none lh-lg">~</div>
                            <div className="col-auto ms-2 py-1">
                                <div className="input-group">
                                    <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} />
                                </div>
                            </div>
                            <div className="col-auto ms-2 py-1">
                                <button className="btn btn-secondary" onClick={this.submitDateRange}>確定</button>
                            </div>
                        </div>
                        <h2>{this.courseName}-{this.videoName}</h2>
                        <VideoWatchGrid
                            watchPeople={this.state.videoInfo.totalWatchPeople}
                            avgWatchPeople={this.state.videoInfo.avgWatchPeople}
                            watchHours={this.state.videoInfo.totalWatchHours}
                            avgWatchHours={this.state.videoInfo.avgWatchHour}
                            watchTimes={this.state.videoInfo.totalWatchTimes}
                            avgWatchTimes={this.state.videoInfo.avgWatchTimes}
                            totalWatchFinish={this.state.totalWatchFinish}
                            avgWatchFinish={this.state.avgWatchFinish}
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                                data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                                aria-selected="true" onClick={() => this.changeAnalysis("timesct", "觀看次數")}>觀看次數</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                                data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                                aria-selected="false" onClick={() => this.changeAnalysis("hoursct", "觀看時數")}>觀看時數</button>
                                        </li>
                                    </ul>
                                    <div className="btn-toolbar mb-2 mb-md-0">
                                        <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                            <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                <Popover>
                                                    <Popover.Header as="h3"></Popover.Header>
                                                    <Popover.Body>
                                                    在篩選條件下，每一天所有影片被所有學生觀看的次數與時數總計
                                                    </Popover.Body>
                                                </Popover>
                                            )}>
                                                <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="input-group">
                                            <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                更多
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li><LinkBtn link={(this.roleType()) ? `/embed/video/${this.state.anayType}/${this.date}/${this.org}?id=${this.videoId}` : `/embed/video/${this.state.anayType}/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}&id=${this.videoId}`} /></li>                                              <li><button className="dropdown-item" onClick={() => this.refresh(0)}>重新整理</button></li>
                                                <li><CSVBtn data={(this.state.anayType === "times") ? this.state.timesAry : this.state.hourAry} /></li>
                                                <li><ImgBtn id={0} /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        {this.analysisType()}
                                        <div className="row py-3">
                                            <div className="col-md-8">
                                                <iframe width="100%" height="380"
                                                    src={this.videoSrc}
                                                    title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen></iframe>
                                            </div>
                                            <div className="col-md-4 text-center pt-4">
                                                <div className="video-item pb-4">
                                                    <h4>影片時長</h4>
                                                    <h5>{this.durarion}</h5>
                                                </div>
                                                <div className="video-item pb-4">
                                                    <h4>平均觀看時長</h4>
                                                    <h5>{this.state.videoInfo.avgWatchHourV}</h5>
                                                </div>
                                                <div className="video-item pb-4">
                                                    <h4>平均每次觀看長度</h4>
                                                    <h5>{this.state.videoInfo.avgWatchHourPerTimes}</h5>
                                                </div>
                                                <div className="video-item pb-4">
                                                    <h4>平均觀看比例</h4>
                                                    <h5>{`${(this.state.videoInfo.totalWatchPeople / this.state.totalWatchPeopleInDateRange * 100).toFixed(2)}%`}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn-toolbar mb-2 mb-md-0 d-flex flex-row-reverse">    
                                            <div className="input-group">
                                                <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    更多
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end">
                                                    <li><LinkBtn link={(this.roleType()) ? `/embed/video/duration/${this.date}/${this.org}?id=${this.videoId}` : `/embed/video/duration/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}&id=${this.videoId}`} /></li>                                          <li><button className="dropdown-item" onClick={() => this.refresh(1)}>重新整理</button></li>
                                                    <li><CSVBtn data={this.state.videoDuration} /></li>
                                                    <li><ImgBtn id={1} /></li>
                                                </ul>
                                            </div>
                                            <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                    <Popover>
                                                        <Popover.Header as="h3"></Popover.Header>
                                                        <Popover.Body>
                                                        影片時間軸上，每一秒被學生觀看的次數的總計，由此圖可以查看影片熱區
                                                        </Popover.Body>
                                                    </Popover>
                                                )}>
                                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        <VideoChart
                                            tickFormat={this.tickFormatDur}
                                            labelx={"影片時間"}
                                            labely={"影片時間軸上的總觀看次數"}
                                            data={this.state.videoDuration}
                                            //domain={[0, 7]}
                                            datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nwatch ${datum.y} times`}
                                        />
                                    </div>
                                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        {this.analysisType()}
                                        <div className="row py-3">
                                            <div className="col-md-8">
                                                <iframe width="100%" height="380"
                                                    src={this.videoSrc}
                                                    title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen></iframe>
                                            </div>
                                            <div className="col-md-4 text-center pt-4">
                                                <div className="video-item pb-4">
                                                    <h4>影片時長</h4>
                                                    <h5>{this.durarion}</h5>
                                                </div>
                                                <div className="video-item pb-4">
                                                    <h4>平均觀看時長</h4>
                                                    <h5>{this.state.videoInfo.avgWatchHourV}</h5>
                                                </div>
                                                <div className="video-item pb-4">
                                                    <h4>平均每次觀看長度</h4>
                                                    <h5>{this.state.videoInfo.avgWatchHourPerTimes}</h5>
                                                </div>
                                                <div className="video-item pb-4">
                                                    <h4>平均觀看比例</h4>
                                                    <h5>{`${(this.state.videoInfo.totalWatchPeople / this.state.totalWatchPeopleInDateRange * 100).toFixed(2)}%`}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn-toolbar mb-2 mb-md-0 d-flex flex-row-reverse">
                                            <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                    <Popover>
                                                        <Popover.Header as="h3"></Popover.Header>
                                                        <Popover.Body>
                                                        影片時間軸上，每一秒被學生觀看的次數的總計，由此圖可以查看影片被觀看的熱區
                                                        </Popover.Body>
                                                    </Popover>
                                                )}>
                                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                                </OverlayTrigger>
                                            </div>
                                            <div className="input-group">
                                                <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    更多
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end">
                                                    <li><LinkBtn link={(this.roleType()) ? `/embed/video/duration/${this.date}/${this.org}?id=${this.videoId}` : `/embed/video/duration/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}&id=${this.videoId}`} /></li>                                          
                                                    <li><button className="dropdown-item" onClick={() => this.refresh(1)}>重新整理</button></li>
                                                    <li><CSVBtn data={this.state.videoDuration} /></li>
                                                    <li><ImgBtn id={1} /></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <VideoChart
                                            tickFormat={this.tickFormatDur}
                                            labelx={"影片時間點"}
                                            labely={"觀看次數"}
                                            data={this.state.videoDuration}
                                            //domain={[0, 7]}
                                            datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nwatch ${datum.y} times`}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 py-3">
                                    <div className="bg-body rounded shadow-sm px-3 py-3">
                                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                    <Popover>
                                                        <Popover.Header as="h3">近期活動</Popover.Header>
                                                        <Popover.Body>
                                                        在篩選條件下，最近關於此影片的觀看紀錄
                                                        </Popover.Body>
                                                    </Popover>
                                                )}>
                                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        <h4>近期活動</h4>
                                        <div className="table-responsive">
                                            {this.state.videoEvent[0] === -1 ? < VideoTable columns={columns} loading={true} /> : < VideoTable columns={columns} data={this.state.videoEvent} columnName={"lastWatchTime"} />}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 py-3">
                                    <div className="bg-body rounded shadow-sm px-3 py-3">
                                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                    <Popover>
                                                        <Popover.Header as="h3">觀看次數</Popover.Header>
                                                        <Popover.Body>
                                                        在篩選條件下，此影片所有學生的觀看次數排行榜
                                                        </Popover.Body>
                                                    </Popover>
                                                )}>
                                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        <h4>觀看次數</h4>
                                        <div className="table-responsive">
                                            {this.state.videoEvent[0] === -1 ? < VideoTable columns={columns1} loading={true} /> : < VideoTable columns={columns1} data={this.state.videoEvent} columnName={"lastWatchTime"} />}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 py-3">
                                    <div className="bg-body rounded shadow-sm px-3 py-3">
                                        <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                            <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                    <Popover>
                                                        <Popover.Header as="h3">觀看時數</Popover.Header>
                                                        <Popover.Body>
                                                        在篩選條件下，此影片所有學生的觀看時數排行榜
                                                        </Popover.Body>
                                                    </Popover>
                                                )}>
                                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        <h4>觀看時數</h4>
                                        <div className="table-responsive">
                                            {this.state.videoEvent[0] === -1 ? < VideoTable columns={columns2} loading={true} /> : < VideoTable columns={columns2} data={this.state.videoEvent} columnName={"lastWatchTime"} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default VideoContent;