import React from 'react';
import { Link } from 'react-router-dom';
import AnalyticsSettingService from '../../services/analyticsSettingService';
import CourseUnitRow from './courseUnitRow';

interface AState {
    courseId?: string;
    courseName?: string;
    sdate?: string;
    edate?: string;
    instructor?: string;
    preTest?: number;
    postTest?: number;
    test?: number;
    homework?: number;
    project?: number;
    passScore?: number;
    questionnaire?: number;
    discussion?: number;
    syncCourse?: number;
    videoDuration?: number;
    totalDuration?: string;
    units?: any;
    status?: string;
}

class NewCourseSetting extends React.Component<{}, AState>{

    private analyticsSettingService: AnalyticsSettingService;
    constructor(props: any) {
        super(props);
        this.analyticsSettingService = new AnalyticsSettingService();
        this.state = {
            courseId: '',
            courseName: '',
            sdate: '',
            edate: '',
            instructor: '',
            preTest: 0,
            postTest: 0,
            test: 0,
            homework: 0,
            project: 0,
            passScore: 60,
            questionnaire: 0,
            discussion: 0,
            syncCourse: 0,
            videoDuration: 0,
            totalDuration: '',
            units: [],
            status: ''
        }
    }

    submitInfo = async () => {
        let data = {
            info: {
                courseId: this.state.courseId,
                courseName: this.state.courseName,
                sdate: this.state.sdate,
                edate: this.state.edate,
                instructor: this.state.instructor?.split(','),
                preTest: this.state.preTest,
                postTest: this.state.postTest,
                test: this.state.test,
                homework: this.state.homework,
                project: this.state.project,
                passScore: this.state.passScore,
                questionnaire: this.state.questionnaire,
                discussion: this.state.discussion,
                syncCourse: this.state.syncCourse,
                videoDuration: this.state.videoDuration,
                totalDuration: this.state.totalDuration
            },
            type: {
                units: this.state.units
            }
        }
        let msg = await this.analyticsSettingService.registerCourse(data);
        if (msg.success === false) {
            this.setState({ status: '課程已存在!' });
        } else {
            this.setState({ status: '' });
            window.location.href = '/lrs/analytics/setting/courses';
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        this.setState({ [e.target.name]: e.target.value }) //html tag <name="name" value="value">
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    addUnit = () => {
        let units = this.state.units;
        units.push({unitName: '', videoDuration: '', unitType: '影片', unitWeek: 1});
        this.setState({units: units});
    }

    onInputChangeHandler = (targetName: string, targetValue: string, targetIndex: string) => {
        let currentUnits = this.state.units;
        currentUnits[targetIndex][targetName] = targetValue;
        this.setState({ units: currentUnits });
    }

    onSelectChangeHandler = (targetName: string, targetValue: string, targetIndex: string) => {
        let currentUnits = this.state.units;
        currentUnits[targetIndex][targetName] = targetValue;
        this.setState({ units: currentUnits });
    }

    render() {
        return (
            <>
                <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><span>分析設定</span>/</li>
                                <li><Link to="/lrs/analytics/setting/courses" style={{ textDecoration: "none", color: "#000000" }}><span>課程設定</span></Link>/</li>
                                <li><span>新增課程</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 pt-1 pb-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="one-user-tab" data-bs-toggle="tab"
                                                data-bs-target="#one-user" type="button" role="tab" aria-controls="one-user"
                                                aria-selected="true">新增課程</button>
                                        </li>
                                        {/* <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="multi-user-tab" data-bs-toggle="tab"
                                                data-bs-target="#multi-user" type="button" role="tab"
                                                aria-controls="multi-user" aria-selected="false">批次匯入課程</button>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="one-user" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div className="col-md-12">
                                            <div className="form-group mt-2">
                                                <label style={{ fontWeight: 800 }}>課程 ID</label>
                                                <input style={{ borderRadius: 0 }} type="text" className="form-control" name="courseId" value={this.state.courseId} onChange={this.handleChange} required />
                                            </div>
                                            <div className="form-group mt-2">
                                                <label style={{ fontWeight: 800 }}>課程名稱</label>
                                                <input style={{ borderRadius: 0 }} type="text" className="form-control" name="courseName" value={this.state.courseName} onChange={this.handleChange} required />
                                            </div>
                                            <div className="form-group mt-2">
                                                <label style={{ fontWeight: 800 }}>課程開始日期</label>
                                                <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} required />
                                            </div>
                                            <div className="form-group mt-2">
                                                <label style={{ fontWeight: 800 }}>課程結束日期</label>
                                                <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} required />
                                            </div>
                                            <div className="form-group mt-2">
                                                <label style={{ fontWeight: 800 }}>課程授課老師 (多位老師用 , 分隔)</label>
                                                <input style={{ borderRadius: 0 }} type="text" className="form-control" name="instructor" value={this.state.instructor} onChange={this.handleChange} required />
                                            </div>
                                            <p className="mt-4" style={{ borderBottom: '1px solid #cfcfcf', fontWeight: 800 }}>課程結業標準</p>
                                            <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                                <label style={{ fontWeight: 800 }}>課程問卷</label>
                                                <input style={{ borderRadius: 0 }} type="number" className="form-control" name="questionnaire" value={this.state.questionnaire} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                                <label style={{ fontWeight: 800 }}>課程前測</label>
                                                <input style={{ borderRadius: 0 }} type="number" className="form-control" name="preTest" value={this.state.preTest} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                                <label style={{ fontWeight: 800 }}>課程單元(平時)測驗</label>
                                                <input style={{ borderRadius: 0 }} type="number" className="form-control" name="test" value={this.state.test} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                                <label style={{ fontWeight: 800 }}>教材閱讀(影片觀看)</label>
                                                <input style={{ borderRadius: 0 }} type="number" className="form-control" name="videoDuration" value={this.state.videoDuration} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                                <label style={{ fontWeight: 800 }}>課程總測驗(後測)</label>
                                                <input style={{ borderRadius: 0 }} type="number" className="form-control" name="postTest" value={this.state.postTest} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                                <label style={{ fontWeight: 800 }}>課程討論</label>
                                                <input style={{ borderRadius: 0 }} type="number" className="form-control" name="discussion" value={this.state.discussion} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                                <label style={{ fontWeight: 800 }}>課程作業</label>
                                                <input style={{ borderRadius: 0 }} type="number" className="form-control" name="homework" value={this.state.homework} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                                <label style={{ fontWeight: 800 }}>同步線上課程</label>
                                                <input style={{ borderRadius: 0 }} type="number" className="form-control" name="syncCourse" value={this.state.syncCourse} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                                <label style={{ fontWeight: 800 }}>課程專題</label>
                                                <input style={{ borderRadius: 0 }} type="number" className="form-control" name="project" value={this.state.project} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                                <label style={{ fontWeight: 800 }}>課程通過分數</label>
                                                <input style={{ borderRadius: 0 }} type="number" className="form-control" name="passScore" value={this.state.passScore} min="0" max="100" onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                                <label style={{ fontWeight: 800 }}>課程影片總時長</label>
                                                <input style={{ borderRadius: 0 }} type="text" className="form-control" name="totalDuration" value={this.state.totalDuration} onChange={this.handleChange} />
                                            </div>
                                            <p className="mt-4" style={{ borderBottom: '1px solid #cfcfcf', fontWeight: 800 }}>課程內容</p>
                                            <div className="form-group mt-2">
                                                <button type="button" className="ml-2" onClick={this.addUnit}>增加單元</button>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>單元名稱</th>
                                                            <th>單元影片長度</th>
                                                            <th>類型</th>
                                                            <th>周次</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.units.map((unit: any, index: number) => <CourseUnitRow 
                                                        unitName={unit.unitName} 
                                                        unitType={unit.unitType}
                                                        unitWeek={unit.unitWeek}
                                                        videoDuration={unit.videoDuration}
                                                        index={index}
                                                        onInputChangeHandler={this.onInputChangeHandler}
                                                        onSelectChangeHandler={this.onSelectChangeHandler}
                                                    />)}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="text-center pb-3 mt-4">
                                                <Link to="/lrs/analytics/setting/courses"
                                                    className="genric-btn circle red-border">取消</Link>&nbsp;&nbsp;<button
                                                        className="genric-btn circle white-border" onClick={this.submitInfo}>建立</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default NewCourseSetting;