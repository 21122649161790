import React from 'react';
import { Link } from 'react-router-dom';
import memoize from 'memoize-one';
import CourseTable from './courseTable';
import AnalyticsSettingService from '../../services/analyticsSettingService';

const columns = memoize((handleAction) => [
    {
        name: "課程 ID",
        selector: "courseId",
        sortable: true,
        width: "10%"
    },
    {
        name: "課程名稱",
        selector: "courseName",
        sortable: true,
        width: "12%",
        cell: (row: any) => <Link to={`/lrs/analytics/setting/course?id=${row.courseId}`}>{row.courseName}</Link>
    },
    {
        name: "開始日期",
        selector: "sdate",
        sortable: true,
        width: "10%"
    },
    {
        name: "結束日期",
        selector: "edate",
        sortable: true,
        width: "10%"
    },
    {
        name: "問卷",
        selector: "questionnaire",
        sortable: true,
        width: "6%"
    },
    {
        name: "前測",
        selector: "preTest",
        sortable: true,
        width: "6%"
    },
    {
        name: "平時測驗",
        selector: "test",
        sortable: true,
        width: "6%"
    },
    {
        name: "影片",
        selector: "videoDuration",
        sortable: true,
        width: "6%"
    },
    {
        name: "後測",
        selector: "postTest",
        sortable: true,
        width: "6%"
    },
    {
        name: "討論",
        selector: "discussion",
        sortable: true,
        width: "6%"
    },
    {
        name: "作業",
        selector: "homework",
        sortable: true,
        width: "5%"
    },
    {
        name: "同步線上課程",
        selector: "syncCourse",
        sortable: true,
        width: "6%"
    },
    {
        name: "專題",
        selector: "project",
        sortable: true,
        width: "5%"
    },
    {
        name: "",
        cell: (row: any) => (row.role === "owner")?<></>:<button className="genric-btn red-border" onClick={() => { handleAction(row) }}>刪除</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true,
        compact: true,
        button: true,
        width: "6%"
    }
]);

interface AState {
    courseList: any[];
}

class CourseSetting extends React.Component<{}, AState>{

    private analyticsSettingService: AnalyticsSettingService;
    constructor(props: any) {
        super(props);
        this.analyticsSettingService = new AnalyticsSettingService();
        this.state = {
            courseList: [-1],
        }
    }

    componentDidMount() {
        this.getCourses();
    }

    getCourses = async () => {
        let courselist = await this.analyticsSettingService.getCourses();
        this.setState({ courseList: courselist });
    }

    deleteRow = async (data: any) => {
        if (window.confirm(`確定刪除 ${data.courseName} ?`)){
            let msg = await this.analyticsSettingService.deleteCourse(data);
            if (msg.success === false)
                alert("刪除失敗")
            else{
                this.getCourses();
            }
        }
    }

    render() {
        return (
            <>
                <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><span>分析設定</span>/</li>
                                <li><span>課程設定</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-1 pb-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                    <h4>課程列表</h4>
                                    <div className="btn-toolbar mb-2 mb-md-0">
                                        <div className="input-group">
                                            <Link to="/lrs/analytics/setting/courses/new" type="button"
                                                className="btn btn-success">
                                                新增課程
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <CourseTable columns={columns(this.deleteRow)} data={this.state.courseList} />
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }

}

export default CourseSetting;