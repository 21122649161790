import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface OCProps { 
    score: number;
    progressResult: any;
    contentType: string;
}

interface OCState {
    modal_show: boolean;
 }

class StudentOverviewTable extends React.Component<OCProps, OCState>{

    constructor(props: any){
        super(props);
        this.state = {
            modal_show: false
        }
    }

    handleModalShow = () => {
        this.setState({
            modal_show: true
        })
    }

    handleModalClose = () => {
        this.setState({
            modal_show: false
        })
    }

    renderQuestionnaireProgress(){
        const renderTable = <table className="table table-striped">
            <thead>
                <tr>
                    <th>問卷名稱</th>
                    <th>完成與否</th>
                </tr>
            </thead>
            <tbody>
            {this.props.progressResult.map((pr: any) => {
                return (
                    <tr>
                        <td>{pr.name}</td>
                        <td>{pr.complete ? <span style={{color: 'green'}}>V</span>:""}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>;
        return renderTable;
    }

    renderTestProgress(){
        const renderTable = <table className="table table-striped">
            <thead>
                <tr>
                    <th>單元測驗名稱</th>
                    <th>成績</th>
                    <th>完成與否</th>
                </tr>
            </thead>
            <tbody>
            {this.props.progressResult.map((pr: any) => {
                return (
                    <tr>
                        <td>{pr.name}</td>
                        <td>{pr.result}</td>
                        <td>{pr.complete ? <span style={{color: 'green'}}>V</span>:""}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>;
        return renderTable;
    }

    renderPreTestProgress(){
        const renderTable = <table className="table table-striped">
            <thead>
                <tr>
                    <th>前測名稱</th>
                    <th>成績</th>
                    <th>完成與否</th>
                </tr>
            </thead>
            <tbody>
            {this.props.progressResult.map((pr: any) => {
                return (
                    <tr>
                        <td>{pr.name}</td>
                        <td>{pr.result}</td>
                        <td>{pr.complete ? <span style={{color: 'green'}}>V</span>:""}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>;
        return renderTable;
    }

    renderPostTestProgress(){
        const renderTable = <table className="table table-striped">
            <thead>
                <tr>
                    <th>後測名稱</th>
                    <th>成績</th>
                    <th>完成與否</th>
                </tr>
            </thead>
            <tbody>
            {this.props.progressResult.map((pr: any) => {
                return (
                    <tr>
                        <td>{pr.name}</td>
                        <td>{pr.result}</td>
                        <td>{pr.complete ? <span style={{color: 'green'}}>V</span>:""}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>;
        return renderTable;
    }

    renderVideoProgress(){
        const renderTable = <table className="table table-striped">
            <thead>
                <tr>
                    <th>教材名稱</th>
                    <th>影片時長(秒)</th>
                    <th>累積觀看時長(秒)</th>
                    <th>完成與否</th>
                </tr>
            </thead>
            <tbody>
            {this.props.progressResult.map((pr: any) => {
                return (
                    <tr>
                        <td>{pr.name}</td>
                        <td>{pr.durations}</td>
                        <td>{pr.result}</td>
                        <td>{pr.complete ? <span style={{color: 'green'}}>V</span>:""}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>;
        return renderTable;
    }

    renderHomeworkProgress(){
        const renderTable = <table className="table table-striped">
            <thead>
                <tr>
                    <th>作業名稱</th>
                    <th>繳交與否</th>
                    <th>分數</th>
                    <th>完成與否</th>
                </tr>
            </thead>
            <tbody>
            {this.props.progressResult.map((pr: any) => {
                return (
                    <tr>
                        <td>{pr.name}</td>
                        <td>{pr.submit === 1 ? <span style={{color: 'green'}}>V</span>:""}</td>
                        <td>{pr.score !== -1 ? pr.score:"老師尚未批改"}</td>
                        <td>{pr.complete ? <span style={{color: 'green'}}>V</span>:""}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>;
        return renderTable;
    }

    renderDiscussionProgress(){
        const renderTable = <table className="table table-striped">
            <thead>
                <tr>
                    <th>討論群組名稱</th>
                    <th>完成與否</th>
                </tr>
            </thead>
            <tbody>
            {this.props.progressResult.map((pr: any) => {
                return (
                    <tr>
                        <td>{pr.name}</td>
                        <td>{pr.complete ? <span style={{color: 'green'}}>V</span>:""}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>;
        return renderTable;
    }

    renderSyncCourseProgress(){
        const renderTable = <table className="table table-striped">
            <thead>
                <tr>
                    <th>同步線上課程名稱</th>
                    <th>通過與否</th>
                </tr>
            </thead>
            <tbody>
            {this.props.progressResult.map((pr: any) => {
                return (
                    <tr>
                        <td>{pr.name}</td>
                        <td>{pr.complete ? <span style={{color: 'green'}}>V</span>:""}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>;
        return renderTable;
    }

    contentType = () => {
        if(this.props.contentType === "video"){
            return (
                <>{this.renderVideoProgress()}</>
            )
        }else if(this.props.contentType === "test"){
            return (
                <>{this.renderTestProgress()}</>
            )
        }else if(this.props.contentType === "questionnaire"){
            return (
                <>{this.renderQuestionnaireProgress()}</>
            )
        }else if(this.props.contentType === "preTest"){
            return (
                <>{this.renderPreTestProgress()}</>
            )
        }else if(this.props.contentType === "postTest"){
            return (
                <>{this.renderPostTestProgress()}</>
            )
        }else if(this.props.contentType === "homework"){
            return (
                <>{this.renderHomeworkProgress()}</>
            )
        }else if(this.props.contentType === "discussion"){
            return (
                <>{this.renderDiscussionProgress()}</>
            )
        }else if(this.props.contentType === "syncCourse"){
            return (
                <>{this.renderSyncCourseProgress()}</>
            )
        }
    }

    render(){
        return (
            <>
                <button onClick={this.handleModalShow} className="btn btn-warning">{this.props.score}</button>
                <Modal show={this.state.modal_show} onHide={this.handleModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>學習進度</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                        {this.contentType()}
                        </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleModalClose}>
                        關閉
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

}

export default StudentOverviewTable;