import React from 'react';
import AccountService from './services/accountService';

interface LProps { }
interface LState {
    account?: string;
    password?: string;
    uname?: string;
}

class Login extends React.Component<LProps, LState> {
    private accountService: AccountService;
    constructor(props: LProps) {
        super(props);
        this.accountService = new AccountService();
        this.state = {
            account: '',
            password: ''
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        this.setState({ [e.target.name]: e.target.value });
    }

    submitInfo = async () => {
        let data = this.state;
        let msg = await this.accountService.login(data);
        if (!msg.success)
            this.setState({ uname: '帳號或密碼錯誤!' });
        else{
            if(msg.role === "student"){
                window.location.href = '/lrs/student/overview';
            }else{
                window.location.href = '/lrs';
            }
        }
    }

    render() {
        return (
            <div>
                <section className="section login">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <img src="/img/table-4.png" width="100%" alt="LOGO" /><br /><br />
                            </div>
                            <div className="col-lg-4">
                                <div className="information">
                                    <br />
                                    <div className="text-center"><h2>登入</h2></div>
                                    <br />
                                    <div className="mb-3 ms-4 me-4">
                                        <input type="text" className="form-control" placeholder="帳號" name="account" value={this.state.account} onChange={this.handleChange} required></input>
                                    </div>
                                    <div className="mb-3 ms-4 me-4">
                                        <input type="password" className="form-control" placeholder="密碼" name="password" value={this.state.password} onChange={this.handleChange} required></input>
                                    </div>
                                    <label style={{ color: "red" }}>{this.state.uname}</label>

                                    {/*
                                <label class="mb-3 ms-4 me-4">
                                    <input type="checkbox" value="remember-me"> 記住我
                                </label>
                                */}
                                    <div className="text-center"><button className="genric-btn red-border circle ms-2" onClick={this.submitInfo}>登入</button>
                                    </div>
                                    {/*
                                    <br />
                                    <hr />
                                    
                                    <p className="ms-4 me-4">
                                        <a href="javascript:;">忘記密碼？</a>
                                    or 還沒有帳號？
                                    <a href="javascript:;">立即</a>
                                    </p>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

}

export default Login;