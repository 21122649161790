import React from 'react';
import CourseUnitRow from './courseUnitRow';
import AnalyticsSettingService from '../../services/analyticsSettingService';
import { Link } from 'react-router-dom';

interface AState {
    id?: string;
    name?: string;
    sdate?: string;
    edate?: string;
    instructor?: string;
    preTest?: number;
    test?: number;
    postTest?: number;
    homework?: number;
    project?: number;
    passScore?: number;
    questionnaire?: number;
    discussion?: number;
    videoDuration?: number;
    totalDuration?: string;
    units?: any;
}

class CourseContentSetting extends React.Component<{}, AState>{

    private analyticsSettingService: AnalyticsSettingService;
    private courseId: string;
    constructor(props: any) {
        super(props);
        this.analyticsSettingService = new AnalyticsSettingService();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.courseId = decodeURIComponent(urlParams.get("id") || "");
        this.state = {
            id: '',
            name: '',
            sdate: '',
            edate: '',
            instructor: '',
            preTest: 0,
            test: 0,
            postTest: 0,
            homework: 0,
            project: 0,
            passScore: 60,
            questionnaire: 0,
            discussion: 0,
            videoDuration: 0,
            totalDuration: '',
            units: []
        };
    }

    componentDidMount() {
        this.getCourse(this.courseId);
    }

    getCourse = async (courseId: string) => {
        let course = await this.analyticsSettingService.getCourse(courseId);
        this.setState({
            id: course.courseId,
            name: course.courseName,
            sdate: course.sdate,
            edate: course.edate,
            instructor: course.instructor? course.instructor.join(',') : '',
            preTest: course.preTest,
            test: course.test,
            postTest: course.postTest,
            homework: course.homework,
            project: course.project,
            passScore: course.passScore,
            questionnaire: course.questionnaire,
            discussion: course.discussion,
            videoDuration: course.videoDuration,
            totalDuration: course.totalDuration,
            units: course.units || []
        });
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        this.setState({ [e.target.name]: e.target.value });
    }

    addUnit = () => {
        let units = this.state.units;
        units.push({unitName: '', videoDuration: '', unitType: '影片', unitWeek: 1});
        this.setState({units: units});
    }

    updateCourse = async () => {
        let data = {
            info: {
                courseId: this.state.id,
                courseName: this.state.name,
                sdate: this.state.sdate,
                edate: this.state.edate,
                instructor: this.state.instructor? this.state.instructor.split(','):[],
                preTest: this.state.preTest,
                test: this.state.test,
                postTest: this.state.postTest,
                homework: this.state.homework,
                project: this.state.project,
                passScore: this.state.passScore,
                questionnaire: this.state.questionnaire,
                discussion: this.state.discussion,
                videoDuration: this.state.videoDuration,
                totalDuration: this.state.totalDuration
            },
            type: {
                units: this.state.units
            }
        };
        await this.analyticsSettingService.addCourseContent(data);
        window.location.href = '/lrs/analytics/setting/courses';
    }

    onInputChangeHandler = (targetName: string, targetValue: string, targetIndex: string) => {
        let currentUnits = this.state.units;
        currentUnits[targetIndex][targetName] = targetValue;
        this.setState({ units: currentUnits });
    }

    onSelectChangeHandler = (targetName: string, targetValue: string, targetIndex: string) => {
        let currentUnits = this.state.units;
        currentUnits[targetIndex][targetName] = targetValue;
        this.setState({ units: currentUnits });
    }

    render() {
        return (
            <>
                <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><span>分析設定</span>/</li>
                                <li><Link to="/lrs/analytics/setting/courses" style={{ textDecoration : 'none', color: '#000' }}><span>課程設定</span></Link>/</li>
                                <li><span>課程內容設定</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <form>
                                <div className="form-group">
                                    <label style={{ fontWeight: 800 }}>課程 ID</label>
                                    <input type="text" style={{ borderRadius: 0 }} className="form-control-plaintext" name="id" value={this.state.id} readOnly />
                                </div>
                                <div className="form-group mt-2">
                                    <label style={{ fontWeight: 800 }}>課程名稱</label>
                                    <input type="text" style={{ borderRadius: 0 }} className="form-control" name="name" value={this.state.name} onChange={this.handleChange} />
                                </div>
                                <div className="form-group mt-2">
                                    <label style={{ fontWeight: 800 }}>課程開始日期</label>
                                    <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} />
                                </div>
                                <div className="form-group mt-2">
                                    <label style={{ fontWeight: 800 }}>課程結束日期</label>
                                    <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} />
                                </div>
                                <div className="form-group mt-2">
                                    <label style={{ fontWeight: 800 }}>課程授課老師 (多位老師用 , 分隔)</label>
                                    <input type="text" style={{ borderRadius: 0 }} className="form-control" name="instructor" value={this.state.instructor} onChange={this.handleChange} />
                                </div>
                                <p className="mt-4" style={{ borderBottom: '1px solid #cfcfcf', fontWeight: 800 }}>課程結業標準</p>
                                <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                    <label style={{ fontWeight: 800 }}>課程問卷</label>
                                    <input type="number" style={{ borderRadius: 0 }} className="form-control" name="questionnaire" value={this.state.questionnaire} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                </div>
                                <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                    <label style={{ fontWeight: 800 }}>課程前測</label>
                                    <input type="number" style={{ borderRadius: 0 }} className="form-control" name="preTest" value={this.state.preTest} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                </div>
                                <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                    <label style={{ fontWeight: 800 }}>課程單元(平時)測驗</label>
                                    <input type="number" style={{ borderRadius: 0 }} className="form-control" name="test" value={this.state.test} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                </div>
                                <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                    <label style={{ fontWeight: 800 }}>課程教材閱讀(影片觀看)</label>
                                    <input type="number" style={{ borderRadius: 0 }} className="form-control" name="videoDuration" value={this.state.videoDuration} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                </div>
                                <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                    <label style={{ fontWeight: 800 }}>課程總測驗(後測)</label>
                                    <input type="number" style={{ borderRadius: 0 }} className="form-control" name="postTest" value={this.state.postTest} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                </div>
                                <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                    <label style={{ fontWeight: 800 }}>課程討論</label>
                                    <input type="number" style={{ borderRadius: 0 }} className="form-control" name="discussion" value={this.state.discussion} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                </div>
                                <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                    <label style={{ fontWeight: 800 }}>課程作業</label>
                                    <input type="number" style={{ borderRadius: 0 }} className="form-control" name="homework" value={this.state.homework} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                </div>
                                <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                    <label style={{ fontWeight: 800 }}>課程專題</label>
                                    <input type="number" style={{ borderRadius: 0 }} className="form-control" name="project" value={this.state.project} step="0.01" min="0" max="1" onChange={this.handleChange} />
                                </div>
                                <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                    <label style={{ fontWeight: 800 }}>課程通過分數</label>
                                    <input type="number" style={{ borderRadius: 0 }} className="form-control" name="passScore" value={this.state.passScore} min="0" max="100" onChange={this.handleChange} />
                                </div>
                                <div className="form-group mt-2" style={{ paddingLeft: '1em' }}>
                                    <label className="col-lg-2 me-2">課程影片總時長</label>
                                    <input type="text" style={{ borderRadius: 0 }} className="form-control" name="totalDuration" value={this.state.totalDuration} onChange={this.handleChange} />
                                </div>
                                <p className="mt-4" style={{ borderBottom: '1px solid #cfcfcf', fontWeight: 800 }}>課程內容</p>
                                <div className="form-group mt-2">
                                    <button type="button" className="ml-2" onClick={this.addUnit}>增加單元</button>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>單元名稱</th>
                                                <th>單元影片長度</th>
                                                <th>類型</th>
                                                <th>周次</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.units.map((unit: any, index: number) => <CourseUnitRow 
                                            unitName={unit.unitName} 
                                            unitType={unit.unitType}
                                            unitWeek={unit.unitWeek}
                                            videoDuration={unit.videoDuration}
                                            index={index}
                                            onInputChangeHandler={this.onInputChangeHandler}
                                            onSelectChangeHandler={this.onSelectChangeHandler}
                                        />)}
                                        </tbody>
                                    </table>
                                </div>
                                <button type="button" className="btn btn-primary" onClick={this.updateCourse}>儲存</button>
                            </form>
                        </div>
                    </div>
                </main>
            </>
        )
    }

}

export default CourseContentSetting;