import React from 'react';
import memoize from 'memoize-one';
import CourseGrid from './courseGrid';
import CourseChart from './courseChart';
import CourseTable from './courseTable';
import CourseService from '../../services/courseService';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ImgBtn from '../opionBtn/exportImage';
import CSVBtn from '../opionBtn/exportCSV';
import LinkBtn from '../opionBtn/exportLink';
import { LoginContext } from '../../loginContext';
import Select from 'react-select';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';

const columns = memoize((handleAction) => [
    {
        name: "課程名稱",
        selector: "name",
        sortable: true,
        //width: "30%",
        wrap: true
    },
    {
        name: "註冊人數",
        selector: "registerPeople",
        sortable: true,
        //width: "12%",
        center: true
    },
    {
        name: "完課人數",
        selector: "finishPeople",
        sortable: true,
        //width: "12%",
        center: true
    },
    {
        name: "完課比例",
        selector: "finishRate",
        sortable: true,
        //width: "12%",
        center: true
    },
    {
        name: "平均花費時間",
        selector: "avgCostTime",
        sortable: true,
        //width: "15%",
        center: true
    },
    {
        name: "詳細內容",
        cell: (row: any) => <button className="genric-btn white-border" onClick={() => { handleAction(row) }}>檢視</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        //width: "10%",
        center: true

    }
]);

interface VLState {
    filterCourseList: any[],
    courseList: any[];
    courseId: '',
    courseInfo: any
    courseListByTimes: any[];
    courseListByHours: any[];
    sdate: string;
    edate: string;
    anayType: string;
    typeText: string;
    sDateDisabled: boolean;
    eDateDisabled: boolean;
    periodDate: string;
}

class CourseList extends React.Component<{}, VLState>{
    private courseService: CourseService;
    private sdate: string;
    private edate: string;
    private org: string;
    private account: string;
    private role: string;
    private date: string;
    static contextType = LoginContext;

    constructor(props: any) {
        super(props);
        this.courseService = new CourseService();
        this.org = "";
        this.account = "";
        this.role = "";
        this.state = {
            filterCourseList: [-1],
            courseInfo: {
                totalRegisterPeople: -1,
                totalFinishPeople: -1,
                avgCostTime: "-1"
            },
            courseListByHours: [-1],
            courseListByTimes: [-1],
            courseList: [-1],
            courseId: '',
            sdate: '2023-02-01',//moment().subtract(14, 'day').format('YYYY-MM-DD'),
            edate: '2023-07-31',//moment().format('YYYY-MM-DD')
            sDateDisabled: true,
            eDateDisabled: true,
            periodDate: '2023-02-01/2023-07-31',
            anayType: "times",
            typeText: "次數"
        }
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
        this.date = "";
    }

    componentDidMount() {
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account)
        this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
        this.getFullCourses(this.date, this.org, this.account, this.role);
        this.getOpenCourseList(this.date, this.org, this.account, this.role);
        this.getCourseHoursByDay(this.date, this.org, this.account, this.role, this.state.courseId);
        this.getCourseTimesByDay(this.date, this.org, this.account, this.role, this.state.courseId);
    }

    resetState = () => {
        this.setState({
            courseInfo: {
                totalRegisterPeople: -1,
                totalFinishPeople: -1,
                avgCostTime: "-1"
            },
            courseListByHours: [-1],
            courseListByTimes: [-1],
            courseList: [-1],
        })
    }

    async getOpenCourseList(date: string, org: string, filter: string, role: string, courseId?: string) {
        let courseInfo: any = await this.courseService.getCoursefinish(date, org, filter, role, courseId);
        this.setState({ courseList: courseInfo.courseList });
        this.setState({ courseInfo: courseInfo.courseInfo });
    }

    getFullCourses = async (date: string, org: string, filter: string, role: string) => {
        let fullCourses: any = await this.courseService.getFullCourses(date, org, filter, role);
        let courseSelectValues = fullCourses.fullCourseList.map((course: any) => {
            return {
                label: course.courseName,
                value: "https://moocs.chihlee.edu.tw/info/"+course.courseId
            }
        });
        this.setState({ filterCourseList: courseSelectValues });
    }

    async getCourseHoursByDay(date: string, org: string, filter: string, role: string, courseId?: string) {
        let courseList: any = await this.courseService.getCourseHoursByDay(date, org, filter, role, courseId);
        let hourAry = await this.settingDailyData(courseList, "hour");
        this.setState({ courseListByHours: hourAry });
    }

    async getCourseTimesByDay(date: string, org: string, filter: string, role: string, courseId?: string) {
        let courseList: any = await this.courseService.getCourseTimesByDay(date, org, filter, role, courseId);
        let timesAry = await this.settingDailyData(courseList, "times");
        this.setState({ courseListByTimes: timesAry });
    }

    viewContent = (data: any) => {
        let id = encodeURIComponent(data._id);
        window.location.href = "/lrs/course/content?id=" + id + "&date=" + this.date;
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    changeAnalysis = (type: any, text: string) => {
        this.setState({ typeText: text })
        this.setState({ anayType: type });
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingDailyData = (ary: any, type: string) => {
        let dataAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false
            let index = 0
            let data = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                }
            }
            if (hasValue) {
                if (type === "hour") {
                    data.y = Math.floor(ary[index].costTime / 3600);
                    dataAry.push(data);
                } else if (type === "times") {
                    data.y = ary[index].attendTimes
                    dataAry.push(data);
                }
            }
            else {
                dataAry.push(data);
            }
        }
        return dataAry;
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    analysisType = () => {
        if (this.state.anayType === "times") {
            return (
                <CourseChart
                    chartType={"line"}
                    tickFormat={this.tickFormat}
                    labelx={"日期"}
                    labely={"次數"}
                    data={this.state.courseListByTimes}
                    datamFunc={({ datum }: { datum: any }) => `${datum.x}\n參與:${datum.y}次`}
                />
            )
        } else {
            return (
                <CourseChart
                    chartType={"line"}
                    tickFormat={this.tickFormat}
                    labelx={"日期"}
                    labely={"小時"}
                    data={this.state.courseListByHours}
                    datamFunc={({ datum }: { datum: any }) => `${datum.x}\n參與:${datum.y}小時`}
                />
            )
        }
    }

    roleType = () => {
        if (this.role === "admin" || this.role === "owner")
            return true;
        else
            return false;
    }

    refresh = () => {
        let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
        this.setState({ courseListByHours: [-1], courseListByTimes: [-1] })
        this.getCourseHoursByDay(date, this.org, this.account, this.role, this.state.courseId);
        this.getCourseTimesByDay(date, this.org, this.account, this.role, this.state.courseId);
    }

    submitDateRange = () => {
        if (moment(this.state.edate).diff(this.state.sdate) < 0) {
            alert("結束日期需大於起始日期")
        } else if (moment(this.state.edate).diff(this.state.sdate, 'days') > 200) {
            alert("日期區間請在六個月內")
        } else {
            this.resetState();
            this.sdate = this.state.sdate;
            this.edate = this.state.edate;
            this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
            this.getOpenCourseList(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getCourseHoursByDay(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getCourseTimesByDay(this.date, this.org, this.account, this.role, this.state.courseId);
        }
    }

    handleCourseChange = (e: any) => {
        if (e === null)
            this.setState({ courseId: '' })
        else
            this.setState({ courseId: e.value })
    }

    periodDateHandler = (e: any) => {
        const targetPeriod = e.target.value;
        if(targetPeriod){
            const periodStartDate = targetPeriod.split('/')[0];
            const periodEndDate = targetPeriod.split('/')[1];
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: true,
                eDateDisabled: true,
                sdate: periodStartDate,
                edate: periodEndDate
            });
        }else{
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: false,
                eDateDisabled: false
            });
        }
    }

    render() {
        return (
            <>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >學習內容分析</span></Link>/</li>
                                <li><span>課程學習分析</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 py-1">
                            <h5>課程</h5>
                            <Select options={this.state.filterCourseList} onChange={this.handleCourseChange} isClearable={true} />
                        </div>
                        <div className="col-md-3 py-1">
                            <h5>學期</h5>
                            <Form.Select value={this.state.periodDate} onChange={this.periodDateHandler}>
                                <option value="">自訂</option>
                                <option value="2023-02-01/2023-07-31">111-第二學期</option>
                                <option value="2022-08-01/2023-01-31">111-第一學期</option>
                                <option value="2022-02-01/2022-07-31">110-第二學期</option>
                                <option value="2021-08-01/2022-01-31">110-第一學期</option>
                                <option value="2021-02-01/2021-07-31">109-第二學期</option>
                                <option value="2020-08-01/2021-01-31">109-第一學期</option>
                            </Form.Select>
                        </div>
                        <div className="col-md-5 py-1">
                            <h5>時間</h5>
                            <div className="row">
                                <div className="col-auto">
                                    <div className="input-group">
                                        <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} disabled={this.state.sDateDisabled} />
                                    </div>
                                </div>
                                <div className="col-auto py-2 m-none lh-lg">~</div>
                                <div className="col-auto">
                                    <div className="input-group">
                                        <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} disabled={this.state.eDateDisabled} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 py-1">
                            <h5>動作</h5>
                            <button className="btn btn-secondary" onClick={this.submitDateRange}>篩選</button>
                        </div>
                    </div>
                    <div className="row">
                        <CourseGrid
                            totalRegisterPeople={this.state.courseInfo.totalRegisterPeople}
                            totalFinishPeople={this.state.courseInfo.totalFinishPeople}
                            avgCostTime={this.state.courseInfo.avgCostTime}
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <div className="py-1"><h4>課程活躍度</h4></div>
                                    <div className="btn-toolbar">
                                        <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                            <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                <Popover>
                                                    <Popover.Header as="h3">課程活躍度</Popover.Header>
                                                    <Popover.Body>
                                                    在篩選條件下，「活躍次數」為所有學生進入課程的次數；「活躍時數」為學生閱讀網頁連結節點與學生觀看影片的花費時間
                                                    </Popover.Body>
                                                </Popover>
                                            )}>
                                                <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="input-group">
                                            <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                <span data-feather="calendar"></span>
                                                更多
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li><LinkBtn link={(this.roleType()) ? `/embed/course/${this.state.anayType}/${this.date}/${this.org}` : `/embed/course/${this.state.anayType}/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}`} /></li>
                                                <li><button className="dropdown-item" onClick={this.refresh}>重新整理</button></li>
                                                <li><CSVBtn data={(this.state.anayType === "times") ? this.state.courseListByTimes : this.state.courseListByHours} /></li>
                                                <li><ImgBtn id={0} /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                                data-bs-target="#active-count-tab-content" type="button" role="tab" aria-controls="home"
                                                aria-selected="true" onClick={() => this.changeAnalysis("times", "次數")}>活躍次數</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                                data-bs-target="#active-timespent-tab-content" type="button" role="tab" aria-controls="profile"
                                                aria-selected="false" onClick={() => this.changeAnalysis("hours", "時數")}>活躍時數</button>
                                    </li>
                                </ul>
                                {this.analysisType()}
                                {this.state.courseList[0] === -1 ? < CourseTable columns={columns(this.viewContent)} loading={true} /> : < CourseTable columns={columns(this.viewContent)} data={this.state.courseList} />}
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default CourseList;