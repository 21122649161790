import App from './App';
import Home from './components/home';
import Login from './login';
import Dashborad from './components/dashboard';
import VideoList from './components/video/videoList';
import VideoContent from './components/video/videoContent';
import CourseList from './components/course/courseList';
import CourseContent from './components/course/courseContent';
import QuizList from './components/quiz/quizList';
import QuizContent from './components/quiz/quizContent';
import Overview from './components/overview/overview';
import Behavior from './components/behavior/behavior';
import ActorList from './components/actor/actorList';
import VerbList from './components/verb/verbList';
import ActivityList from './components/activity/activityList';
import ActivityContent from './components/activity/activityContent'
import ActorContent from './components/actor/actorContent';
import Discussion from './components/discussion/discussion';
import Admin from './components/account/admin';
import NewAdmin from './components/account/newAdmin';
import Teacher from './components/account/teacher';
import NewTeacher from './components/account/newTeacher';
import Student from './components/account/student';
import StudentOverview from './components/overview/studentOverview';
import NewStudent from './components/account/newStudent';
import Blockchain from './components/blockchain/blockchain';
import NotFound from './notFound';
import OverviewEmbed from './components/overview/overviewEmbed';
import VerbEmbed from './components/verb/verbEmbed';
import ActorEmbed from './components/actor/actorEmbed';
import ActivityEmbed from './components/activity/activityEmbed';
import DiscussionEmbed from './components/discussion/discussionEmbed';
import VideoEmbed from './components/video/videoEmbed';
import CourseEmbed from './components/course/courseEmbed';
import QuizEmbed from './components/quiz/quizEmbed';
import { Redirect } from "react-router-dom";
import AnalyticsCourseSetting from './components/analytics/courseSetting';
import AnalyticsCourseContentSetting from './components/analytics/courseContentSetting';
import NewAnalyticsCourseSetting from './components/analytics/newCourseSetting';
import Past from './components/past/past';

const routes = [
  {
    path: '/',
    exact: true,
    render: () => <Redirect to={"/home"} />
  },
  {
    path: '/lrs',
    exact: true,
    render: () => <Redirect to={"/lrs/past"} />
  },
  {
    path: '/lrs',
    component: Dashborad,
    routes: [
      {
        path: '/lrs/overview',
        component: Overview,
        exact: true
      },
      {
        path: '/lrs/behavior',
        component: Behavior,
        exact: true
      },
      {
        path: '/lrs/actor',
        component: ActorList,
        exact: true
      },
      {
        path: '/lrs/actor/content',
        component: ActorContent,
        exact: true
      },
      {
        path: '/lrs/verb',
        component: VerbList,
        exact: true
      },
      {
        path: '/lrs/activity',
        component: ActivityList,
        exact: true
      },
      {
        path: '/lrs/activity/content',
        component: ActivityContent,
        exact: true
      },
      {
        path: '/lrs/video',
        component: VideoList,
        exact: true
      },
      {
        path: '/lrs/video/content',
        component: VideoContent,
        exact: true
      },
      {
        path: '/lrs/course',
        component: CourseList,
        exact: true
      },
      {
        path: '/lrs/course/content',
        component: CourseContent,
        exact: true
      },
      {
        path: '/lrs/quiz',
        component: QuizList,
        exact: true
      },
      {
        path: '/lrs/quiz/content',
        component: QuizContent,
        exact: true
      },
      {
        path: '/lrs/discussion',
        component: Discussion,
        exact: true
      },
      {
        path: '/lrs/blockchain',
        component: Blockchain,
        exact: true
      },
      {
        path: '/lrs/account/admin',
        component: Admin,
        exact: true
      },
      {
        path: '/lrs/account/admin/new',
        component: NewAdmin,
        exact: true
      },
      {
        path: '/lrs/account/teacher',
        component: Teacher,
        exact: true
      },
      {
        path: '/lrs/account/teacher/new',
        component: NewTeacher,
        exact: true
      },
      {
        path: '/lrs/account/student',
        component: Student,
        exact: true
      },
      {
        path: '/lrs/account/student/new',
        component: NewStudent,
        exact: true
      },
      {
        path: '/lrs/analytics/setting/courses',
        component: AnalyticsCourseSetting,
        exact: true
      },
      {
        path: '/lrs/analytics/setting/courses/new',
        component: NewAnalyticsCourseSetting,
        exact: true
      },
      {
        path: '/lrs/analytics/setting/course',
        component: AnalyticsCourseContentSetting,
        exact: true
      },
      {
        path: '/lrs/past',
        component: Past,
        exact: true
      },
      {
        path: '/lrs/student/overview',
        component: StudentOverview,
        exact: true
      },
      {
        path: '/*',
        component: NotFound,
      }
    ]
  },
  {
    path: '/embed/overview/:type/:date/:org',
    component: OverviewEmbed,
    exact: true
  },
  {
    path: '/embed/verb/:type/:date/:org',
    component: VerbEmbed,
    exact: true
  },
  {
    path: '/embed/actor/:type/:date/:org',
    component: ActorEmbed,
    exact: true
  },
  {
    path: '/embed/act/:type/:date/:org',
    component: ActivityEmbed,
    exact: true
  },
  {
    path: '/embed/discuss/:type/:date/:org',
    component: DiscussionEmbed,
    exact: true
  },
  {
    path: '/embed/video/:type/:date/:org',
    component: VideoEmbed,
    exact: true
  },
  {
    path: '/embed/course/:type/:date/:org',
    component: CourseEmbed,
    exact: true
  },
  {
    path: '/embed/quiz/:type/:date/:org',
    component: QuizEmbed,
    exact: true
  },
  {
    path: '/',
    component: App,
    routes: [
      {
        path: '/home',
        component: Home,
        exact: true //can not use subpath ex: /user/123, only /user
      },
      {
        path: '/login',
        component: Login,
        exact: true
      },
      {
        path: '/*',
        component: NotFound,
      }
    ]
  }
];

export { routes };