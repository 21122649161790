import React from 'react';
import CourseChart from './courseChart';
import CourseTable from './courseTable';
import CourseService from '../../services/courseService';
import moment from 'moment';
import ImgBtn from '../opionBtn/exportImage';
import CSVBtn from '../opionBtn/exportCSV';
import LinkBtn from '../opionBtn/exportLink';
import { LoginContext } from '../../loginContext';
import { HeatMapGrid } from 'react-grid-heatmap';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const dateDiff = {
    inDays: function (d1: any, d2: any) {
        let t2 = d2.getTime();
        let t1 = d1.getTime();

        return `${Math.round((t2 - t1) / (24 * 3600 * 1000))}天前`;
    },

    inWeeks: function (d1: any, d2: any) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();

        return Math.round((t2 - t1) / (24 * 3600 * 1000 * 7));
    },

    inMonths: function (d1: any, d2: any) {
        var d1Y = d1.getFullYear();
        var d2Y = d2.getFullYear();
        var d1M = d1.getMonth();
        var d2M = d2.getMonth();

        return `${(d2M + 12 * d2Y) - (d1M + 12 * d1Y)}月前`;
    },

    inYears: function (d1: any, d2: any) {
        return `${d2.getFullYear() - d1.getFullYear()}年前`;
    }
}

const columns = [
    {
        name: "",
        selector: "event",
        width: "100%",
        cell: (row: any) => {
            return (
                <li><span className="text-gray">{row.event.date} {row.event.time}</span>
                    <p>{row.event.name} {row.event.do} {row.event.what}<span className="text-red"> {dateDiff.inDays(new Date(row.event.date), new Date())}</span></p>
                </li>
            )
        }

    }
];

const tableStyle = {
    border: "none",
    boxShadow: "none"
};



interface CCCState {
    anayType: string;
    sdate: string;
    edate: string;
    typeText: string;
    courseListByTimes: any[];
    courseListByHours: any[];
    warningList: any[];
    warningWeekList: any[];
    warningX: any[];
    warningY: any[];
    scoreList: any[];
    eventList: any[];
    conditionalRowStyles: any[];
}
interface CCCProps {
    courseId: string;
    date: string;
}

class CourseComplexContent extends React.Component<CCCProps, CCCState>{
    private courseService: CourseService;
    private sdate: string;
    private edate: string;
    private courseId: string;
    private org: string;
    private account: string;
    private role: string;
    private date: string;
    static contextType = LoginContext;

    constructor(props: any) {
        super(props);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.courseId = this.props.courseId;
        this.courseService = new CourseService();
        this.org = "";
        this.account = "";
        this.role = "";
        this.state = {
            sdate: "2021-02-01" || moment().clone().startOf('month').format('YYYY-MM-DD'),
            edate: "2021-05-20" || moment().clone().endOf('month').format('YYYY-MM-DD'),
            anayType: "timesct",
            courseListByHours: [-1],
            courseListByTimes: [-1],
            scoreList: [-1],
            eventList: [-1],
            warningList: [[]],
            warningWeekList: [],
            warningX: [],
            warningY: [],
            conditionalRowStyles: [],
            typeText: "次數"
        }
        this.sdate = moment(this.props.date.slice(0, 8)).format('YYYY-MM-DD');
        this.edate = moment(this.props.date.slice(8, 16)).format('YYYY-MM-DD');
        this.date = decodeURIComponent(urlParams.get("date") || "");
    }

    componentDidMount() {
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account)
        this.getSingleCourseHours(this.date, this.courseId, this.org, this.account, this.role);
        this.getSingleCourseAttend(this.date, this.courseId, this.org, this.account, this.role);
        this.getSingleCourseAttendByUser(this.date, this.courseId, this.org, this.account, this.role);
        this.getSingleCourseEvent(this.courseId, this.org, this.account, this.role);
        this.getCourseWarning(this.courseId, this.org, this.account, this.role);
        this.getCourseWeekWarning(this.courseId, this.org, this.account, this.role);
    }

    resetState = () => {
        this.setState({
            courseListByHours: [-1],
            courseListByTimes: [-1],
            scoreList: [-1],
            eventList: [-1]
        })
    }

    getSingleCourseHours = async (date: string, courseId: string, org: string, filter: string, role: string) => {
        let courseList: any = await this.courseService.getSingleCourseHours(date, courseId, org, filter, role);
        let hourAry = await this.settingDailyData(courseList, "hour");
        this.setState({ courseListByHours: hourAry });
    }

    getSingleCourseAttend = async (date: string, courseId: string, org: string, filter: string, role: string) => {
        let courseList: any = await this.courseService.getSingleCourseAttend(date, courseId, org, filter, role);
        let timesAry = await this.settingDailyData(courseList, "times");
        this.setState({ courseListByTimes: timesAry });
    }

    getSingleCourseAttendByUser = async (date: string, courseId: string, org: string, filter: string, role: string) => {
        let courseList: any = await this.courseService.getSingleCourseAttendByUser(date, courseId, org, filter, role);
        this.setState({ scoreList: courseList });
    }

    getSingleCourseEvent = async (courseId: string, org: string, filter: string, role: string) => {
        let courseList: any = await this.courseService.getSingleCourseEvent(courseId, org, filter, role);
        this.setState({ eventList: courseList });
    }

    getCourseWarning = async (courseId: string, org: string, filter: string, role: string): Promise<any> => {
        let courseList: any = await this.courseService.getCourseWarning(courseId, org, filter, role);
        let [x, y, warningList] = await this.processData(courseList)
        console.log(x, y, warningList)
        this.setState({ warningList: warningList, warningX: x, warningY: y });
    }

    getCourseWeekWarning = async (courseId: string, org: string, filter: string, role: string): Promise<any> => {
        let courseList: any = await this.courseService.getCourseWeekWarning(courseId, org, filter, role);
        let weekWarningList = await this.processChart(courseList)
        this.setState({ warningWeekList: weekWarningList });
    }

    changeAnalysis = (type: any, text: string) => {
        this.setState({ typeText: text })
        this.setState({ anayType: type });
    }

    refresh = (id: number) => {
        switch (id) {
            case 0: {
                this.setState({ courseListByTimes: [-1], courseListByHours: [-1] });
                this.getSingleCourseHours(this.date, this.courseId, this.org, this.account, this.role);
                this.getSingleCourseAttend(this.date, this.courseId, this.org, this.account, this.role);
                break;
            }
            case 1: {
                this.setState({ scoreList: [-1] });
                this.getSingleCourseAttendByUser(this.date, this.courseId, this.org, this.account, this.role);
                break;

            }
        }
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingDailyData = (ary: any, type: string) => {
        let dataAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false
            let index = 0
            let data = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                }
            }
            if (hasValue) {
                if (type === "hour") {
                    data.y = Math.floor(ary[index].costTime / 3600);
                    dataAry.push(data);
                } else if (type === "times") {
                    data.y = ary[index].attendTimes
                    dataAry.push(data);
                }
            }
            else {
                dataAry.push(data);
            }
        }
        return dataAry;
    }

    roleType = () => {
        if (this.role === "admin" || this.role === "owner")
            return true;
        else
            return false;
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    processData = (list: any[]) => {
        let xLabels = []
        let yLabels = []
        if (list.length === 0)
            return [[], [], []];
        for (let i = 0; i < list[0].result.length; i++) {
            yLabels.push(`Week${i + 1}`);
        }
        for (let i = 0; i < list.length; i++) {
            xLabels.push(list[i].name);
        }
        let dataAry: any[] = []
        for (let i = 0; i < list[0].result.length; i++) {
            let data: any = []
            for (let j = 0; j < list.length; j++) {
                data.push((list[j].result[i]) ? 0 : 100)
            }
            dataAry.push(data);
        }
        return [xLabels, yLabels, dataAry];
    }

    processChart = (list: any[]) => {
        if (list.length === 0)
            return [];
        let warningAry: any[] = []
        for (let i = 0; i < 2; i++) {
            let warn: any[] = [];
            for (let j = 0; j < list.length; j++) {
                let data: any = {
                    x: `W${j + 1}`,
                    y: 0,
                    people: 0
                }
                switch (i) {
                    case 0:
                        data.y = Math.round((list[j].pass / (list[j].pass + list[j].fail) * 100))
                        data.people = list[j].pass
                        warn.push(data)
                        break;
                    case 1:
                        data.y = 100 - Math.round((list[j].pass / (list[j].pass + list[j].fail) * 100))
                        data.people = list[j].fail
                        warn.push(data)
                        break;
                }
            }
            warningAry.push(warn)
        }
        return warningAry;
    }

    analysisType = () => {
        if (this.state.anayType === "timesct") {
            return (
                <CourseChart
                    chartType={"line"}
                    tickFormat={this.tickFormat}
                    labelx={"日期"}
                    labely={"次數"}
                    data={this.state.courseListByTimes}
                    datamFunc={({ datum }: { datum: any }) => `${datum.x}\n參與${datum.y}次`}
                />
            )
        } else {
            return (
                <CourseChart
                    chartType={"line"}
                    tickFormat={this.tickFormat}
                    labelx={"日期"}
                    labely={"小時"}
                    data={this.state.courseListByHours}
                    datamFunc={({ datum }: { datum: any }) => `${datum.x}\n參與${datum.y}小時`}

                />
            )
        }
    }

    render() {
        return (
            <>
                <div className="bg-body rounded shadow-sm px-3">
                    <div
                        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h4>課程活躍度</h4>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">課程活躍度</Popover.Header>
                                        <Popover.Body>
                                        在篩選條件下，「活躍次數」為所有學生進入此課程的次數；「活躍時數」為學生閱讀網頁連結節點與學生觀看影片的花費時間
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                                </div>
                            <div className="input-group">
                                <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <span data-feather="calendar"></span>
                                    更多
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li><LinkBtn link={(this.roleType()) ? `/embed/course/${this.state.anayType}/${this.props.date}/${this.org}?id=${this.courseId}` : `/embed/course/${this.state.anayType}/${this.props.date}/${this.org}?role=${this.context.role}&acct=${this.account}&id=${this.courseId}`} /></li>
                                    <li><button className="dropdown-item" onClick={() => this.refresh(0)}>重新整理</button></li>
                                    <li><CSVBtn data={(this.state.anayType === "times") ? this.state.courseListByTimes : this.state.courseListByHours} /></li>
                                    <li><ImgBtn id={0} /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                    data-bs-target="#active-count-tab-content" type="button" role="tab" aria-controls="home"
                                    aria-selected="true" onClick={() => this.changeAnalysis("timesct", "次數")}>活躍次數</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                    data-bs-target="#active-timespent-tab-content" type="button" role="tab" aria-controls="profile"
                                    aria-selected="false" onClick={() => this.changeAnalysis("hoursct", "時數")}>活躍時數</button>
                        </li>
                    </ul>
                    {this.analysisType()}
                </div>
                <div className="bg-body rounded shadow-sm px-3">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h4>活躍度 v.s. 成績</h4>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="input-group">
                                <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                        <Popover>
                                            <Popover.Header as="h3">活躍度 v.s. 成績</Popover.Header>
                                            <Popover.Body>
                                            在篩選條件下， 此課程內每一位學生其「活躍次數」與「平均成績」的比較，可藉由此分析圖找到異常表現之學生，例如:活躍度高但成績低
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                                <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                    data-bs-toggle="dropdown" aria-expanded="false">更多
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li><LinkBtn link={(this.roleType()) ? `/embed/course/score/${this.date}/${this.org}?id=${this.courseId}` : `/embed/course/score/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}&id=${this.courseId}`} /></li>
                                    <li><button className="dropdown-item" onClick={() => this.refresh(1)}>重新整理</button></li>
                                    <li><CSVBtn data={this.state.scoreList} /></li>
                                    <li><ImgBtn id={1} /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <CourseChart
                        chartType={"point"}
                        labelx={"活躍度"}
                        labely={"成績"}
                        data={this.state.scoreList}
                        datamFunc={({ datum }: { datum: any }) => `${datum.name}\n活躍度:${datum.x} \n成績:${datum.y}`}
                    />
                </div>
                <div className="bg-body rounded shadow-sm px-3">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h5>學習預警</h5>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                    <Popover>
                                        <Popover.Header as="h3">學習預警</Popover.Header>
                                        <Popover.Body>
                                        每一周去預測每一位學生其學習行為總體表現能否在期末順利結業
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                    <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                            <div className="input-group">
                                <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                    data-bs-toggle="dropdown" aria-expanded="false">更多
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li><LinkBtn link={(this.roleType()) ? `/embed/course/warning/${this.date}/${this.org}?id=${this.courseId}` : `/embed/course/warning/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}&id=${this.courseId}`} /></li>
                                    <li><button className="dropdown-item" onClick={() => this.refresh(2)}>重新整理</button></li>
                                    <li><CSVBtn data={this.state.scoreList} /></li>
                                    <li><ImgBtn id={2} /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <CourseChart
                        chartType={"stack"}
                        labelx={"學習週數"}
                        labely={"%"}
                        dataStack={this.state.warningWeekList}
                        datamFunc={({ datum }: { datum: any }) => `${datum.y}%\n(${datum.people})`}
                    />
                </div>
                <div className="bg-body rounded shadow-sm px-3" style={{ overflow: "auto" }}>
                    <h4>預警結果</h4>
                    <HeatMapGrid
                        data={this.state.warningList}
                        xLabels={this.state.warningX}
                        yLabels={this.state.warningY}
                        // Reder cell with tooltip
                        cellStyle={(_x, _y, ratio) => ({
                            background: (ratio > 0.5) ? `rgb(0, 255, 0)` : `rgb(255, 0, 0)`,
                        })}
                        cellHeight='3rem'
                        xLabelsPos='top'
                        yLabelsPos='left'
                        square
                    />
                </div>
                <div className="bg-body rounded shadow-sm px-3">
                    <h4>近期活動</h4>
                    {this.state.eventList[0] === -1 ? < CourseTable columns={columns} loading={true} /> : < CourseTable columns={columns} data={this.state.eventList} tableStyle={tableStyle} />}
                </div>
            </>
        )
    }
}

export default CourseComplexContent;