import React from 'react';
import CourseGrid from './courseGrid';
import CourseChart from './courseChart';
import CourseTable from './courseTable';
import CourseComplexContent from './courseComplexContent';
import CourseService from '../../services/courseService';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ImgBtn from '../opionBtn/exportImage';
import CSVBtn from '../opionBtn/exportCSV';
import LinkBtn from '../opionBtn/exportLink';
import { LoginContext } from '../../loginContext';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const columns = [
    {
        name: "單元編號",
        selector: "_id",
        sortable: true,
        width: "20%",
        wrap: true
    },
    {
        name: "單元名稱",
        selector: "unitName",
        sortable: true,
        width: "25%",
        center: true
    },
    {
        name: "單元類型",
        selector: "type",
        sortable: true,
        width: "12%",
        center: true
    },
    {
        name: "總活動次數",
        selector: "attendTimes",
        sortable: true,
        width: "12%",
        center: true
    },
    {
        name: "平均活動次數",
        selector: "avgAttendTime",
        sortable: true,
        width: "12%",
        center: true
    },
    {
        name: "平均花費時間",
        selector: "avgCostTime",
        sortable: true,
        width: "12%",
        center: true
    }
];

interface CCState {
    courseInfo: {
        name: string;
        finishPeople: number;
        registerPeople: number;
        avgCostTime: string;
        sdate: string;
        edate: string;
    };
    anayType: string;
    typeText: string;
    courseListByUnit: any[];
    unitType: string;
    unitTypeText: string;
}

class CourseContent extends React.Component<{}, CCState>{
    private courseService: CourseService;
    private courseId: string;
    private date: string;
    private sdate: string;
    private edate: string;
    private org: string;
    private account: string;
    private role: string;
    static contextType = LoginContext;

    constructor(props: any) {
        super(props);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.courseId = decodeURIComponent(urlParams.get("id") || "");
        this.date = decodeURIComponent(urlParams.get("date") || "");
        this.org = "";
        this.account = "";
        this.role = "";
        this.courseService = new CourseService();
        this.state = {
            courseInfo: {
                name: "",
                finishPeople: -1,
                registerPeople: -1,
                avgCostTime: "-1",
                sdate: "",
                edate: ""
            },
            anayType: "complex",
            unitType: "times",
            courseListByUnit: [-1],
            typeText: "綜合分析",
            unitTypeText: "次數"
        }
        this.sdate = moment(this.date.slice(0, 8)).format('YYYY-MM-DD');
        this.edate = moment(this.date.slice(8, 16)).format('YYYY-MM-DD');
    }

    componentDidMount() {
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account)
        this.getSingleCourse(this.date, this.courseId, this.org, this.account, this.role);
        this.getSingleCourseAttendByUnit(this.date, this.courseId, this.org, this.account, this.role);
    }

    getSingleCourse = async (date: string, courseId: string, org: string, filter: string, role: string) => {
        let course = await this.courseService.getSingleCourse(date, courseId, org, filter, role);
        this.setState({ courseInfo: course });
    }

    getSingleCourseAttendByUnit = async (date: string, courseId: string, org: string, filter: string, role: string) => {
        let courseList: any = await this.courseService.getSingleCourseAttendByUnit(date, courseId, org, filter, role);
        this.setState({ courseListByUnit: courseList });
    }

    changeAnalysis = (type: any, text: string) => {
        this.setState({ typeText: text })
        this.setState({ anayType: type });
    }

    changeDailyType = (type: any, text: string) => {
        this.setState({ unitType: type })
        this.setState({ unitTypeText: text });
    }

    roleType = () => {
        if (this.role === "admin" || this.role === "owner")
            return true;
        else
            return false;
    }

    tickFormat = (x: any) => {
        if (x === "")
            return x
    }

    dailyType = () => {
        if (this.state.unitType === "times") {
            return (
                <CourseChart
                    chartType={"bar"}
                    labelx={"單元"}
                    labely={"次數"}
                    x={"unitName"}
                    y={"attendTimes"}
                    tickFormat={this.tickFormat}
                    data={this.state.courseListByUnit}
                    datamFunc={({ datum }: { datum: any }) => `${(datum.unitName).replace(/(.{5})/g, "$1\n")}\n參與:${datum.attendTimes}次`}

                />
            )
        } else {
            return (
                <CourseChart
                    chartType={"bar"}
                    labelx={"單元"}
                    labely={"小時"}
                    x={"unitName"}
                    y={"costTime"}
                    tickFormat={this.tickFormat}
                    data={this.state.courseListByUnit}
                    datamFunc={({ datum }: { datum: any }) => `${(datum.unitName).replace(/(.{5})/g, "$1\n")}\n參與:${datum.costTime}小時`}
                />
            )
        }
    }

    analysisType = () => {
        if (this.state.anayType === "complex") {
            return (
                <CourseComplexContent courseId={this.courseId} date={this.date} />
            )
        } else {
            return (
                <>
                    <div className="bg-body rounded shadow-sm px-3">
                        <div
                            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h4>單元學習</h4>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">單元學習</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，「活躍次數」指所有學生在每一個單元節點的學習行為紀錄數量；「活躍時數」指所有學生在每一個單元節點的學習所花費的總時數
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                <div className="input-group">
                                    <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <span data-feather="calendar"></span>
                                        更多
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li><LinkBtn link={(this.roleType()) ? `/embed/course/unit${this.state.unitType}/${this.date}/${this.org}?id=${this.courseId}` : `/embed/course/unit${this.state.unitType}/${this.date}/${this.org}?role=${this.context.role}&acct=${this.account}&id=${this.courseId}`} /></li>                  
                                        <li><button className="dropdown-item" onClick={this.refresh}>重新整理</button></li>
                                        <li><CSVBtn data={(this.state.anayType === "times") ? this.state.courseListByUnit : this.state.courseListByUnit} /></li>
                                        <li><ImgBtn id={0} /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <ul className="nav nav-tabs" id="courseUnitTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="course-unit-count-tab" data-bs-toggle="tab"
                                        data-bs-target="#active-count-tab-content" type="button" role="tab" aria-controls="home"
                                        aria-selected="true" onClick={() => this.changeDailyType("times", "次數")}>學習次數</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="course-unit-timespent-tab" data-bs-toggle="tab"
                                        data-bs-target="#active-timespent-tab-content" type="button" role="tab" aria-controls="profile"
                                        aria-selected="false" onClick={() => this.changeDailyType("hours", "時數")}>學習時數</button>
                            </li>
                        </ul>
                        {this.dailyType()}
                    </div>
                    <div className="bg-body rounded shadow-sm px-3">
                        <div
                            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        </div>
                        {this.state.courseListByUnit[0] === -1 ? < CourseTable columns={columns} loading={true} /> : < CourseTable columns={columns} data={this.state.courseListByUnit} columnName={"name"} />}
                    </div>
                </>
            )
        }
    }

    refresh = () => {
        this.setState({ courseListByUnit: [-1] });
        this.getSingleCourseAttendByUnit(this.date, this.courseId, this.org, this.account, this.role);
    }

    render() {
        return (
            <>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >學習內容分析</span></Link>/</li>
                                <li><Link to="/lrs/course" style={{ textDecoration: "none", color: "#000000" }}><span>課程學習分析</span></Link>/</li>
                                <li><span>{this.state.courseInfo.name}</span></li>
                            </ul>
                        </div>
                        <h2>{this.state.courseInfo.name}<span className="fs-4 text-gray ms-3">{this.state.courseInfo.sdate} ~ {(this.state.courseInfo.edate === "Invalid date") ? moment().format('YYYY-MM-DD') : this.state.courseInfo.edate}</span></h2>
                        <CourseGrid
                            totalRegisterPeople={this.state.courseInfo.registerPeople}
                            totalFinishPeople={this.state.courseInfo.finishPeople}
                            avgCostTime={this.state.courseInfo.avgCostTime}
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                            data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                            aria-selected="true" onClick={() => this.changeAnalysis("complex", "綜合分析")}>綜合分析</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                            data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                            aria-selected="false" onClick={() => this.changeAnalysis("common", "內容分析")} >內容分析</button>
                                    </li>
                                </ul>
                            </div>
                            {this.analysisType()}
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default CourseContent;