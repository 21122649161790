import React from 'react';
import memoize from 'memoize-one';
import moment from 'moment';
import ActivityTable from './activityTable';
import ActivityService from '../../services/activityService';
import CourseService from '../../services/courseService';
import Loading from 'react-loading';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../loginContext';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import Select from 'react-select';

const columns = memoize((handleAction) => [
    {
        name: "活動名稱",
        selector: "name",
        sortable: true,
        width: "30%",
        wrap: true
    },
    {
        name: "活動類型",
        sortable: true,
        width: "10%",
        center: true,
        cell: (row: any) => {
            switch(row.type ? row.type : ''){
                case "影片":
                    return <i className="fa fa-video-camera" aria-hidden="true"></i>;
                case "課程":
                    return '課程';
                case "連結":
                    return <i className="fa fa-link" aria-hidden="true"></i>;
                case "檔案":
                    return <i className="fa fa-file" aria-hidden="true"></i>;
                case "題目":
                    return <i className="fa fa-question-circle" aria-hidden="true"></i>;
                case "測驗":
                    return <i className="fa fa-pencil-square-o" aria-hidden="true"></i>;
                case "作業":
                    return <i className="fa fa-pencil" aria-hidden="true"></i>;
                case "討論":
                    return <i className="fa fa-comments-o" aria-hidden="true"></i>;
                default:
                    return '';
            }
        }
    },
    {
        name: "學習人數",
        selector: "people",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "紀錄數量",
        selector: "total",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "學習時數",
        selector: "totalCostTime",
        sortable: true,
        width: "20%",
        center: true
    },
    {
        name: "詳細內容",
        cell: (row: any) => <button className="genric-btn white-border" onClick={() => { handleAction(row) }}>檢視</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true,
        compact: true,
        button: true,
        width: "10%"
    }
]);

interface QLState {
    courseList: any[];
    courseId: string;
    actList: any[];
    totalAct: number;
    sdate: string;
    edate: string;
    sDateDisabled: boolean;
    eDateDisabled: boolean;
    periodDate: string;
}

class ActivityList extends React.Component<{}, QLState>{
    private courseService: CourseService;
    private activityService: ActivityService;
    private sdate: string;
    private edate: string;
    private org: string;
    private account: string;
    private role: string;
    static contextType = LoginContext;

    constructor(props: any) {
        super(props);
        this.courseService = new CourseService();
        this.activityService = new ActivityService();
        this.org = "";
        this.account = "";
        this.role = "";
        this.state = {
            courseList: [-1],
            courseId: '',
            totalAct: -1,
            actList: [-1],
            sdate: '2023-02-01',//moment().subtract(14, 'day').format('YYYY-MM-DD'),
            edate: '2023-07-31',//moment().format('YYYY-MM-DD')
            sDateDisabled: true,
            eDateDisabled: true,
            periodDate: '2023-02-01/2023-07-31'
        }
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
    }

    componentDidMount() {
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account)
        let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
        this.getFullCourses(date, this.org, this.account, this.role);
        this.getTotalAct(date, this.org, this.account, this.role, this.state.courseId);
        this.getActList(date, this.org, this.account, this.role, this.state.courseId);
    }

    resetState = () => {
        this.setState({
            totalAct: -1,
            actList: [-1]
        })
    }

    getTotalAct = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let act = await this.activityService.getTotalAct(date, org, filter, role, courseId);
        this.setState({ totalAct: act.totalAct })
    }

    getActList = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let actList = await this.activityService.getActList(date, org, filter, role, courseId)
        this.setState({ actList: actList })
    }

    viewContent = (data: any) => {
        let sdate = this.state.sdate;
        let edate = this.state.edate;
        let id = encodeURIComponent(data._id);
        window.location.href = `/lrs/activity/content?id=${id}&sdate=${sdate}&edate=${edate}&name=${data.name}&type=${data.type}`
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    submitDateRange = () => {
        if (moment(this.state.edate).diff(this.state.sdate) < 0) {
            alert("結束日期需大於起始日期")
        } else if (moment(this.state.edate).diff(this.state.sdate, 'days') > 200) {
            alert("日期區間請在六個月內")
        } else {
            this.resetState();
            this.sdate = this.state.sdate;
            this.edate = this.state.edate;
            let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
            this.getTotalAct(date, this.org, this.account, this.role, this.state.courseId);
            this.getActList(date, this.org, this.account, this.role, this.state.courseId);
        }
    }

    renderLoading() {
        return <Loading type={"spin"} color={"#ffffff"} height={'20%'} width={'20%'}></Loading>
    }

    periodDateHandler = (e: any) => {
        const targetPeriod = e.target.value;
        if(targetPeriod){
            const periodStartDate = targetPeriod.split('/')[0];
            const periodEndDate = targetPeriod.split('/')[1];
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: true,
                eDateDisabled: true,
                sdate: periodStartDate,
                edate: periodEndDate
            });
        }else{
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: false,
                eDateDisabled: false
            });
        }
    }

    handleCourseChange = (e: any) => {
        if (e === null)
            this.setState({ courseId: '' })
        else
            this.setState({ courseId: e.value })
    }

    getFullCourses = async (date: string, org: string, filter: string, role: string) => {
        let fullCourses: any = await this.courseService.getFullCourses(date, org, filter, role);
        let courseSelectValues = fullCourses.fullCourseList.map((course: any) => {
            return {
                label: course.courseName,
                value: "https://moocs.chihlee.edu.tw/info/"+course.courseId
            }
        });
        this.setState({ courseList: courseSelectValues });
    }

    render() {
        return (
            <>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <ul id="title-spacer" className="breadcrumbs">
                                <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >學習行為分析</span></Link>/</li>
                                <li><span>學習活動紀錄</span></li>
                            </ul>
                        </div>
                        <div className="d-md-flex justify-content-md-end">
                            <div className="col-md-3 py-1">
                                <h5>課程</h5>
                                <Select options={this.state.courseList} onChange={this.handleCourseChange} isClearable={true} />
                            </div>
                            <div className="col-md-3 py-1" style={{marginLeft: '0.5em'}}>
                                <h5>學期</h5>
                                <Form.Select value={this.state.periodDate} onChange={this.periodDateHandler}>
                                    <option value="">自訂</option>
                                    <option value="2023-02-01/2023-07-31">111-第二學期</option>
                                    <option value="2022-08-01/2023-01-31">111-第一學期</option>
                                    <option value="2022-02-01/2022-07-31">110-第二學期</option>
                                    <option value="2021-08-01/2022-01-31">110-第一學期</option>
                                    <option value="2021-02-01/2021-07-31">109-第二學期</option>
                                    <option value="2020-08-01/2021-01-31">109-第一學期</option>
                                </Form.Select>
                            </div>
                            <div className="col-md-5 py-1">
                                <h5>時間</h5>
                                <div className="row">
                                    <div className="col-auto ms-2">
                                        <div className="input-group">
                                            <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} disabled={this.state.sDateDisabled} />
                                        </div>
                                    </div>
                                    <div className="col-auto ms-2 py-2 m-none lh-lg">~</div>
                                    <div className="col-auto ms-2">
                                        <div className="input-group">
                                            <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} disabled={this.state.eDateDisabled} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto ms-2 py-1">
                                <h5>動作</h5>
                                <button className="btn btn-secondary" onClick={this.submitDateRange}>篩選</button>
                            </div>
                        </div>
                        <div className="col-md-2 py-1">
                            <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                                <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                    <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">活動</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，所有學習紀錄中的活動
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <h2>{(this.state.totalAct === -1) ? this.renderLoading() : this.state.totalAct}</h2>
                                <h5>活動</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-3 pb-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div
                                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <h3>活動紀錄分析</h3>
                                    <div className="btn-toolbar mb-2 mb-md-0">
                                        <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                            <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                                <Popover>
                                                    <Popover.Header as="h3">活動紀錄分析</Popover.Header>
                                                    <Popover.Body>
                                                    在篩選條件下，所有學習活動的進行學習的學生、相關的紀錄數量、花費的時間
                                                    </Popover.Body>
                                                </Popover>
                                            )}>
                                                <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                            </OverlayTrigger>
                                        </div>
                                        {/*
                                        <div className="input-group">
                                            <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                功能
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li><a className="dropdown-item" href="#">Share</a></li>
                                                <li><a className="dropdown-item" href="#">Export</a></li>
                                            </ul>
                                        </div>
                                        */}
                                    </div>
                                </div>
                                {this.state.actList[0] === -1 ? < ActivityTable columns={columns(this.viewContent)} loading={true} /> : <ActivityTable columns={columns(this.viewContent)} data={this.state.actList} columnName={"name"} />}
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default ActivityList;