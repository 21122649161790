import HttpHandler from './APIServices';
import { OverviewAPI } from './API';
import { roleFilter } from './queryFilter';

class OverviewService {

    private api: OverviewAPI;

    constructor() {
        this.api = new OverviewAPI()
    }

    getStudentCourseProgress = async (date: string, org: string, filter:string, role:string, courseId?: string) => {
        let url = `${this.api.basePath}${this.api.getStudentCourseProgress}/${date}/${org}`;
        url = await roleFilter(url + '?', filter, role, courseId)
        let data = await HttpHandler(url);
        return data;
    }

}

export default OverviewService;