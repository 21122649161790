import moment from 'moment';
import React from 'react';
import DiscussionChart from './discussionChart';
import DiscussionTable from './discussionTable';
import DiscussionService from '../../services/discussionService';
import CourseService from '../../services/courseService';
import Select from 'react-select';
import Loading from 'react-loading';
import { Link } from 'react-router-dom';
import ImgBtn from '../opionBtn/exportImage';
import CSVBtn from '../opionBtn/exportCSV';
import LinkBtn from '../opionBtn/exportLink';
import ReactWordcloud from 'react-wordcloud'
import { LoginContext } from '../../loginContext';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';

interface FProps { }
interface FState {
    discussList: any[];
    mostList: any[];
    courseList: any[];
    courseId: string;
    userList: any[];
    hotCourseList: any[];
    discusCourseList: any[];
    mostLegend: any[];
    courseLegend: any[];
    userLegend: any[];
    discussionInfo: any;
    words: any[];
    course: string;
    sdate: string;
    edate: string;
    sDateDisabled: boolean;
    eDateDisabled: boolean;
    periodDate: string;
}

const columns = [
    {
        name: "課程名稱",
        selector: "name",
        sortable: true,
        //width: "55%",
        center: true,
        wrap: true
    },
    {
        name: "修課人數",
        selector: "total",
        sortable: true,
        //width: "15%",
        center: true,
    },
    {
        name: "主題討論",
        selector: "discuss",
        sortable: true,
        //width: "15%",
        center: true,
    },
    {
        name: "主題回覆",
        selector: "replied",
        sortable: true,
        //width: "15%",
        center: true,
    }
];

class Discussion extends React.Component<FProps, FState>{
    private courseService: CourseService;
    private discussionService: DiscussionService;
    private sdate: string;
    private edate: string;
    private org: string;
    private account: string;
    private role: string;
    private date: string;
    static contextType = LoginContext;

    constructor(props: any) {
        super(props);
        this.courseService = new CourseService();
        this.discussionService = new DiscussionService();
        this.org = "";
        this.account = "";
        this.role = "";
        this.state = {
            discussList: [-1],
            mostList: [-1],
            courseList: [-1],
            courseId: '',
            hotCourseList: [-1],
            userList: [-1],
            discusCourseList: [-1],
            courseLegend: [],
            userLegend: [],
            mostLegend: [],
            words: [],
            discussionInfo: {
                total: -1,
                people: -1,
                course: -1
            },
            course: "",
            sdate: '2023-02-01',//moment().subtract(7, 'day').format('YYYY-MM-DD'),
            edate: '2023-07-31',//moment().format('YYYY-MM-DD'),
            sDateDisabled: true,
            eDateDisabled: true,
            periodDate: '2023-02-01/2023-07-31'
        }
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
        this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '')
    }

    componentDidMount() {
        this.org = this.context.org;
        this.role = this.context.role;
        this.account = window.btoa(this.context.account);
        this.getFullCourses(this.date, this.org, this.account, this.role);
        this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
        this.getDisscussionInfo(this.date, this.org, this.account, this.role, this.state.courseId);
        this.getDiscussionByDaily(this.date, this.org, this.account, this.role, this.state.courseId);
        this.getDiscussionByMost(this.date, this.org, this.account, this.role, this.state.courseId);
        this.getDiscussionByCourse(this.date, this.org, this.account, this.role, this.state.courseId);
        this.getDiscussionByUser(this.date, this.org, this.account, this.role, this.state.courseId);
        this.getDiscussListByCourse(this.date, this.org, this.account, this.role, this.state.courseId);
        this.getWordCloud(this.date, this.org, this.account, this.role, this.state.course);
    }

    resetState = () => {
        this.setState({
            discussList: [-1],
            mostList: [-1],
            hotCourseList: [-1],
            userList: [-1],
            discusCourseList: [-1],
            courseLegend: [],
            userLegend: [],
            mostLegend: [],
            words: [],
            discussionInfo: {
                total: -1,
                people: -1,
                course: -1
            },
        })
    }

    getFullCourses = async (date: string, org: string, filter: string, role: string) => {
        let fullCourses: any = await this.courseService.getFullCourses(date, org, filter, role);
        let courseSelectValues = fullCourses.fullCourseList.map((course: any) => {
            return {
                label: course.courseName,
                value: "https://moocs.chihlee.edu.tw/info/"+course.courseId
            }
        });
        this.setState({ courseList: courseSelectValues });
    }

    getDisscussionInfo = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let info = await this.discussionService.getDisscussionInfo(date, org, filter, role, courseId);
        this.setState({ discussionInfo: info });
    }

    getDiscussionByDaily = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let data = await this.discussionService.getDiscussionByDaily(date, org, filter, role, courseId);
        let discussList = await this.settingDailyData(data)
        this.setState({ discussList: discussList });
    }

    getDiscussionByMost = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let data = await this.discussionService.getDiscussionByMost(date, org, filter, role, courseId);
        let mostLegend = await this.processLegend(data);
        this.setState({ mostList: data, mostLegend: mostLegend });
    }

    getDiscussionByCourse = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let data = await this.discussionService.getDiscussionByCourse(date, org, filter, role, courseId);
        let courseLegend = await this.processLegend(data);
        this.setState({ hotCourseList: data, courseLegend: courseLegend });
    }

    getDiscussionByUser = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let data = await this.discussionService.getDiscussionByUser(date, org, filter, role, courseId);
        let userLegend = await this.processLegend(data);
        this.setState({ userList: data, userLegend: userLegend });
    }

    getDiscussListByCourse = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let data = await this.discussionService.getDiscussListByCourse(date, org, filter, role, courseId);
        this.setState({ discusCourseList: data });
    }

    getWordCloud = async (date: string, org: string, filter: string, role: string, courseId: string): Promise<any> => {
        let data = await this.discussionService.getWordCloud(date, org, filter, role, courseId);
        this.setState({ words: data });
    }

    processLegend = (ary: any[]) => {
        let nameAry = [];
        for (let i = 0; i < ary.length; i++) {
            let data = {
                name: `${Math.ceil(ary[i].total / ary.map((x: any) => x.total).reduce((a: any, c: any) => a + c) * 100)}% ${ary[i].name}`
            }
            nameAry.push(data)
        }
        return nameAry;
    }

    getDaysBetweenDates = (startDate: string, endDate: string) => {
        let now = moment(startDate).clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY/MM/DD'));
            now.add(1, 'days');
        }
        return dates;
    }

    settingDailyData = (ary: any[]) => {
        let discussAry = [];
        let dayOfMonth = moment(this.edate).diff(moment(this.sdate), 'days') + 1;
        let dateAry = this.getDaysBetweenDates(this.sdate, this.edate);
        for (let i = 0; i < dayOfMonth; i++) {
            let hasValue = false
            let index = 0
            let discussData = {
                x: dateAry[i],
                y: 0
            }
            for (let j = 0; j < ary.length; j++) {
                if (`${ary[j].year}/${("0" + ary[j].month).slice(-2)}/${("0" + ary[j].day).slice(-2)}` === dateAry[i]) {
                    hasValue = true;
                    index = j;
                }
            }
            if (hasValue) {
                discussData.y = Math.floor(ary[index].total)
                discussAry.push(discussData);
            }
            else {
                discussAry.push(discussData);
            }
        }
        return discussAry;
    }

    handleCourse = (e: any) => {
        if (e === null)
            this.setState({ course: "" })
        else
            this.setState({ course: e.value })
    }

    handleCourseChange = (e: any) => {
        if (e === null)
            this.setState({ courseId: '' })
        else
            this.setState({ courseId: e.value })
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    roleType = () => {
        if (this.role === "admin" || this.role === "owner")
            return true;
        else
            return false;
    }

    refresh = (id: number) => {
        let date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
        switch (id) {
            case 0: {
                this.setState({ discussList: [-1] })
                this.getDiscussionByDaily(date, this.org, this.account, this.role, this.state.course);
                break;
            }
            case 1: {
                this.setState({ mostList: [-1] });
                this.getDiscussionByMost(date, this.org, this.account, this.role, this.state.course);
                break;
            }
            case 2: {
                this.setState({ courseList: [-1] });
                this.getDiscussionByCourse(date, this.org, this.account, this.role, this.state.course);
                break;
            }
            case 3: {
                this.setState({ userList: [-1] });
                this.getDiscussionByUser(date, this.org, this.account, this.role, this.state.course);
                break;
            }
        }
    }

    submitDateRange = () => {
        if (moment(this.state.edate).diff(this.state.sdate) < 0) {
            alert("結束日期需大於起始日期")
        } else if (moment(this.state.edate).diff(this.state.sdate, 'days') > 200) {
            alert("日期區間請在六個月內")
        } else {
            this.sdate = this.state.sdate;
            this.edate = this.state.edate;
            this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
            this.resetState();
            this.getDisscussionInfo(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getDiscussionByDaily(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getDiscussionByMost(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getDiscussionByCourse(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getDiscussionByUser(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getDiscussListByCourse(this.date, this.org, this.account, this.role, this.state.courseId);
            this.getWordCloud(this.date, this.org, this.account, this.role, this.state.courseId);
        }
    }

    tickFormat = (x: any) => {
        if (x === moment(this.sdate).format('YYYY/MM/DD') || x === moment(this.edate).format('YYYY/MM/DD'))
            return x
    }

    renderLoading() {
        return <Loading type={"spin"} color={"#ffffff"} height={'20%'} width={'20%'}></Loading>
    }

    periodDateHandler = (e: any) => {
        const targetPeriod = e.target.value;
        if(targetPeriod){
            const periodStartDate = targetPeriod.split('/')[0];
            const periodEndDate = targetPeriod.split('/')[1];
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: true,
                eDateDisabled: true,
                sdate: periodStartDate,
                edate: periodEndDate
            });
        }else{
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: false,
                eDateDisabled: false
            });
        }
    }

    render() {
        return (
            <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <ul id="title-spacer" className="breadcrumbs">
                            <li><Link to="/lrs" style={{ textDecoration: "none", color: "#000000" }}><span >社群互動分析</span></Link> \</li>
                            <li><span>論壇討論分析</span></li>
                        </ul>
                    </div>
                    <div className="d-md-flex justify-content-md-end">
                        <div className="col-auto col-lg-3 ms-2 py-1">
                            <h5>課程</h5>
                            <Select options={this.state.courseList} onChange={this.handleCourseChange} isClearable={true} />
                        </div>
                        <div className="col-md-3 py-1" style={{marginLeft: '0.5em'}}>
                            <h5>學期</h5>
                            <Form.Select value={this.state.periodDate} onChange={this.periodDateHandler}>
                                <option value="">自訂</option>
                                <option value="2023-02-01/2023-07-31">111-第二學期</option>
                                <option value="2022-08-01/2023-01-31">111-第一學期</option>
                                <option value="2022-02-01/2022-07-31">110-第二學期</option>
                                <option value="2021-08-01/2022-01-31">110-第一學期</option>
                                <option value="2021-02-01/2021-07-31">109-第二學期</option>
                                <option value="2020-08-01/2021-01-31">109-第一學期</option>
                            </Form.Select>
                        </div>
                        <div className="col-md-5 py-1" style={{marginLeft: '0.5em'}}>
                            <h5>時間</h5>
                            <div className="row">
                                <div className="col-auto">
                                    <div className="input-group">
                                        <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} disabled={this.state.sDateDisabled} />
                                    </div>
                                </div>
                                <div className="col-auto py-2 m-none lh-lg">~</div>
                                <div className="col-auto">
                                    <div className="input-group">
                                        <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} disabled={this.state.eDateDisabled} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto ms-2 py-1">
                            <h5>動作</h5>
                            <button className="btn btn-secondary" onClick={this.submitDateRange}>確定</button>
                        </div>
                    </div>
                    <div className="col-md-2 py-1">
                        <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                            <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                        <Popover>
                                            <Popover.Header as="h3">主題討論</Popover.Header>
                                            <Popover.Body>
                                            在篩選條件下，所有張貼的主題討論的數量
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <h2>{(this.state.discussionInfo.total === -1) ? this.renderLoading() : this.state.discussionInfo.total}</h2>
                            <h5>主題討論</h5>
                        </div>
                    </div>
                    <div className="col-md-2 py-1">
                        <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                            <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                        <Popover>
                                            <Popover.Header as="h3">參與學生</Popover.Header>
                                            <Popover.Body>
                                            在篩選條件下，所有參與討論的不重複的學生數量
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <h2>{(this.state.discussionInfo.people === -1) ? this.renderLoading() : this.state.discussionInfo.people}</h2>
                            <h5>參與學生</h5>
                        </div>
                    </div>
                    <div className="col-md-2 py-1">
                        <div className="h-100 p-3 bg-dark rounded-3 text-center text-white">
                            <div className="btn-toolbar mb-2 mb-md-0 grid-explain">
                                <div style={{marginRight: '.5em', cursor: 'pointer'}}>
                                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                        <Popover>
                                            <Popover.Header as="h3">課程</Popover.Header>
                                            <Popover.Body>
                                            在篩選條件下，所有參與討論的不重複的課程數量 
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                        <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <h2>{(this.state.discussionInfo.course === -1) ? this.renderLoading() : this.state.discussionInfo.course}</h2>
                            <h5>課程</h5>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>討論熱度</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">討論熱度</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，每一天的討論紀錄數量，包含：學生張貼、閱讀與回覆討論    
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/discuss/event/${this.date}/${this.org}?id=${this.state.course}` : `/embed/discuss/event/${this.date}/${this.org}?id=${this.state.course}&role=${this.context.role}&acct=${this.account}`} /></li>
                                            <li><button className="dropdown-item" onClick={() => this.refresh(0)}>重新整理</button></li>
                                            <li><CSVBtn data={(this.state.discussList)} /></li>
                                            <li><ImgBtn id={0} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <DiscussionChart
                                tickFormat={this.tickFormat}
                                labelx={"日期"}
                                labely={"筆數"}
                                data={this.state.discussList}
                                datamFunc={({ datum }: { datum: any }) => `in ${datum.x} \nhas ${datum.y} records`}
                                chartType={"line"}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>熱門討論主題</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">熱門討論主題</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，有最多被閱讀與回覆的前十名討論主題
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/discuss/most/${this.date}/${this.org}?id=${this.state.course}` : `/embed/discuss/most/${this.date}/${this.org}?id=${this.state.course}&role=${this.context.role}&acct=${this.account}`} /></li>
                                            <li><button className="dropdown-item" onClick={() => this.refresh(1)}>重新整理</button></li>
                                            <li><CSVBtn data={(this.state.mostList)} /></li>
                                            <li><ImgBtn id={1} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <DiscussionChart
                                height={200}
                                legendx={250}
                                legendGutter={10}
                                legendFontSize={6}
                                data={this.state.mostList}
                                name={this.state.mostLegend}
                                x={"name"}
                                y={"total"}
                                chartType={"circle"}
                                datum={({ datum }: { datum: any }) => `${(datum.name)}...\n${Math.ceil(datum.total / this.state.mostList.map(x => x.total).reduce((a, c) => a + c) * 100)}%`}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>熱門討論課程</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">熱門討論課程</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，有最多討論的前十名課程   
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/discuss/course/${this.date}/${this.org}?id=${this.state.course}` : `/embed/discuss/course/${this.date}/${this.org}?id=${this.state.course}&role=${this.context.role}&acct=${this.account}`} /></li>
                                            <li><button className="dropdown-item" onClick={() => this.refresh(2)}>重新整理</button></li>
                                            <li><CSVBtn data={(this.state.hotCourseList)} /></li>
                                            <li><ImgBtn id={2} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <DiscussionChart
                                height={200}
                                legendx={250}
                                legendGutter={10}
                                legendFontSize={6}
                                data={this.state.hotCourseList}
                                name={this.state.courseLegend}
                                x={"name"}
                                y={"total"}
                                chartType={"circle"}
                                datum={({ datum }: { datum: any }) => `${(datum.name)}...\n${Math.ceil(datum.total / this.state.courseList.map(x => x.total).reduce((a, c) => a + c) * 100)}%`}
                            />                        </div>
                    </div>
                    <div className="col-md-6 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>意見領袖</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">意見領袖</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，參與討論最多的前十名學生
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">更多
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><LinkBtn link={(this.roleType()) ? `/embed/discuss/user/${this.date}/${this.org}?id=${this.state.course}` : `/embed/discuss/user/${this.date}/${this.org}?id=${this.state.course}&role=${this.context.role}&acct=${this.account}`} /></li>
                                            <li><button className="dropdown-item" onClick={() => this.refresh(3)}>重新整理</button></li>
                                            <li><CSVBtn data={(this.state.userList)} /></li>
                                            <li><ImgBtn id={3} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <DiscussionChart
                                data={this.state.userList}
                                name={this.state.userLegend}
                                x={"name"}
                                y={"total"}
                                chartType={"circle"}
                                datum={({ datum }: { datum: any }) => `${(datum.name)}...\n${Math.ceil(datum.total / this.state.userList.map(x => x.total).reduce((a, c) => a + c) * 100)}%`}
                            />                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>討論關鍵字</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">討論關鍵字</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，討論主題的關鍵字
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            {this.state.words.length === 0 ? <h4 style={{textAlign: 'center'}}>Loading</h4> : <ReactWordcloud words={this.state.words}/>}
                        </div>
                    </div>
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                {/* <div className="col-6">
                                    <Select options={[]} onChange={this.handleCourse} isClearable={true} />
                                </div> */}
                                <h4>課程討論概況</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">課程討論概況</Popover.Header>
                                                <Popover.Body>
                                                在篩選條件下，每一個課程的討論狀況
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group">
                                        {/* <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            功能
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                            {this.state.discusCourseList[0] === -1 ? < DiscussionTable columns={columns} loading={true} /> : < DiscussionTable columns={columns} data={this.state.discusCourseList} />}
                        </div>
                    </div>
                </div>
            </main>
        );
    }

}

export default Discussion;