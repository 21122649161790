import React from 'react';
import { Link } from 'react-router-dom';
import AccountService from '../services/accountService';
import { LoginContext } from '../loginContext';
import config from '../config';

interface NProps { }
interface NState { }

class Navbar extends React.Component<NProps, NState>{
    private accountService: AccountService
    static contextType = LoginContext;


    constructor(props: NProps) {
        super(props);
        this.accountService = new AccountService();
    }

    logout = async () => {
        let data = await this.accountService.logout();
        if (data.success) {
            window.location.href = "/login";
        }
    }

    render() {
        return (
            <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
                <Link className="navbar-brand col-md-3 col-lg-2 me-0 px-3" to="/lrs" style={{ textDecoration: "none", color: "#FFFFFF" }}>
                    {/* <img src="/img/visca-logo-white.png" width="90"
                    alt="LOGO" /> */}
                    <p>{config.SITE_NAME}</p>
                </Link>
                {/* <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="/lrs"><img src="/img/chihleeCombineB.png" width="90"
                    alt="LOGO" /></a> */}
                <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <ul id="noname" className="navbar-nav px-3">
                    <li className="nav-item text-nowrap me-2">
                        <span className="nav-link text-white">{this.context.uname}</span>
                    </li>
                    {!config.HIDE_LOGOUT && <li className="nav-item text-nowrap l-border">
                        <Link to="" className="nav-link" onClick={this.logout}>登出</Link>
                    </li>}
                </ul>
            </header>
        )
    }
}

export default Navbar;