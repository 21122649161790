import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import {
    VictoryTheme,
    VictoryChart,
    VictoryBar,
    VictoryStack,
    VictoryAxis,
    VictoryGroup,
    VictoryLegend,
    VictoryTooltip
} from 'victory';

const genderMaleData =  [
    [{x: "108-1", y: 18, label: "男性\n通過:18%"}, {x: "108-2", y: 12, label: "男性\n通過:12%"}, {x: "109-1", y: 23, label: "男性\n通過:23%"}],
    [{x: "108-1", y: 82, label: "男性\n未通過:82%"}, {x: "108-2", y: 88, label: "男性\n未通過:88%"}, {x: "109-1", y: 77, label: "男性\n未通過:77%"}]
];
const genderFemaleData =  [
    [{x: "108-1", y: 20, label: "女性\n通過:20%"}, {x: "108-2", y: 28, label: "女性\n通過:28%"}, {x: "109-1", y: 28, label: "女性\n通過:28%"}],
    [{x: "108-1", y: 80, label: "女性\n未通過:80%"}, {x: "108-2", y: 72, label: "女性\n未通過:72%"}, {x: "109-1", y: 72, label: "女性\n未通過:72%"}]
];
// 入學管道: 身心障礙
const sourceDisabledData = [
    [{x: "108-1", y: 0, label: "身心障礙\n通過:0%"}, {x: "108-2", y: 0, label: "身心障礙\n通過:0%"}, {x: "109-1", y: 0, label: "身心障礙\n通過:0%"}],
    [{x: "108-1", y: 100, label: "身心障礙\n未通過:100%"}, {x: "108-2", y: 100, label: "身心障礙\n未通過:100%"}, {x: "109-1", y: 100, label: "身心障礙\n未通過:100%"}]
];
// 入學管道：特殊選材
const sourceSpecialData = [
    [{x: "108-1", y: 0, label: "特殊選材\n通過:0%"}, {x: "108-2", y: 0, label: "特殊選材\n通過:0%"}, {x: "109-1", y: 33, label: "特殊選材\n通過:33%"}],
    [{x: "108-1", y: 100, label: "特殊選材\n未通過:100%"}, {x: "108-2", y: 100, label: "特殊選材\n未通過:100%"}, {x: "109-1", y: 67, label: "特殊選材\n未通過:67%"}]
];
// 入學管道：繁星計畫
const sourceStarData = [
    [{x: "108-1", y: 5, label: "繁星計畫\n通過:5%"}, {x: "108-2", y: 37, label: "繁星計畫\n通過:37%"}, {x: "109-1", y: 23, label: "繁星計畫\n通過:23%"}],
    [{x: "108-1", y: 95, label: "繁星計畫\n未通過:95%"}, {x: "108-2", y: 63, label: "繁星計畫\n未通過:63%"}, {x: "109-1", y: 77, label: "繁星計畫\n未通過:77%"}]
];
// 入學管道：甄選入學
const sourceSelectionData = [
    [{x: "108-1", y: 8, label: "甄選入學\n通過:8%"}, {x: "108-2", y: 14, label: "甄選入學\n通過:14%"}, {x: "109-1", y: 16, label: "甄選入學\n通過:16%"}],
    [{x: "108-1", y: 92, label: "甄選入學\n未通過:92%"}, {x: "108-2", y: 86, label: "甄選入學\n未通過:86%"}, {x: "109-1", y: 84, label: "甄選入學\n未通過:84%"}]
];
// 入學管道：登記分發
const sourceRegisterData = [
    [{x: "108-1", y: 8, label: "登記分發\n通過:8%"}, {x: "108-2", y: 18, label: "登記分發\n通過:18%"}, {x: "109-1", y: 20, label: "登記分發\n通過:20%"}],
    [{x: "108-1", y: 92, label: "登記分發\n未通過:92%"}, {x: "108-2", y: 82, label: "登記分發\n未通過:82%"}, {x: "109-1", y: 80, label: "登記分發\n未通過:80%"}]
];
// 入學管道：技優甄審
const sourceSkillData = [
    [{x: "108-1", y: 12, label: "技優甄審\n通過:12%"}, {x: "108-2", y: 23, label: "技優甄審\n通過:23%"}, {x: "109-1", y: 22, label: "技優甄審\n通過:22%"}],
    [{x: "108-1", y: 88, label: "技優甄審\n未通過:88%"}, {x: "108-2", y: 77, label: "技優甄審\n未通過:77%"}, {x: "109-1", y: 78, label: "技優甄審\n未通過:78%"}]
];
// 入學管道：申請入學
const sourceApplyData = [
    [{x: "108-1", y: 25, label: "申請入學\n通過:25%"}, {x: "108-2", y: 33, label: "申請入學\n通過:33%"}, {x: "109-1", y: 33, label: "申請入學\n通過:33%"}],
    [{x: "108-1", y: 75, label: "申請入學\n未通過:75%"}, {x: "108-2", y: 67, label: "申請入學\n未通過:67%"}, {x: "109-1", y: 67, label: "申請入學\n未通過:67%"}]
];
// 入學管道：甄試入學
const sourceAssessmentData = [
    [{x: "108-1", y: 28, label: "甄試入學\n通過:28%"}, {x: "108-2", y: 32, label: "甄試入學\n通過:32%"}, {x: "109-1", y: 51, label: "甄試入學\n通過:51%"}],
    [{x: "108-1", y: 72, label: "甄試入學\n未通過:72%"}, {x: "108-2", y: 68, label: "甄試入學\n未通過:68%"}, {x: "109-1", y: 49, label: "甄試入學\n未通過:49%"}]
];
// 入學管道：轉學考試
const sourceTransferData = [
    [{x: "108-1", y: 37, label: "轉學考試\n通過:37%"}, {x: "108-2", y: 22, label: "轉學考試\n通過:22%"}, {x: "109-1", y: 40, label: "轉學考試\n通過:40%"}],
    [{x: "108-1", y: 63, label: "轉學考試\n未通過:63%"}, {x: "108-2", y: 78, label: "轉學考試\n未通過:78%"}, {x: "109-1", y: 60, label: "轉學考試\n未通過:60%"}]
];
// 入學管道：招生入學
const sourceAdmissionData = [
    [{x: "108-1", y: 50, label: "招生入學\n通過:50%"}, {x: "108-2", y: 26, label: "招生入學\n通過:26%"}, {x: "109-1", y: 42, label: "招生入學\n通過:42%"}],
    [{x: "108-1", y: 50, label: "招生入學\n未通過:50%"}, {x: "108-2", y: 74, label: "招生入學\n未通過:74%"}, {x: "109-1", y: 58, label: "招生入學\n未通過:58%"}]
];
// 入學管道：免試入學
const sourceFreeData = [
    [{x: "108-1", y: 57, label: "免試入學\n通過:57%"}, {x: "108-2", y: 69, label: "免試入學\n通過:69%"}, {x: "109-1", y: 45, label: "免試入學\n通過:45%"}],
    [{x: "108-1", y: 43, label: "免試入學\n未通過:43%"}, {x: "108-2", y: 31, label: "免試入學\n未通過:31%"}, {x: "109-1", y: 55, label: "免試入學\n未通過:55%"}]
];
// 學制: 日間部
const systemDayData = [
    [{x: "108-1", y: 12, label: "日間部\n通過:12%"}, {x: "108-2", y: 21, label: "日間部\n通過:21%"}, {x: "109-1", y: 23, label: "日間部\n通過:23%"}],
    [{x: "108-1", y: 88, label: "日間部\n未通過:88%"}, {x: "108-2", y: 79, label: "日間部\n未通過:79%"}, {x: "109-1", y: 77, label: "日間部\n未通過:77%"}]
];
// 學制: 夜間部
const systemNightData = [
    [{x: "108-1", y: 50, label: "夜間部\n通過:50%"}, {x: "108-2", y: 26, label: "夜間部\n通過:26%"}, {x: "109-1", y: 28, label: "夜間部\n通過:28%"}],
    [{x: "108-1", y: 50, label: "夜間部\n未通過:50%"}, {x: "108-2", y: 74, label: "夜間部\n未通過:74%"}, {x: "109-1", y: 72, label: "夜間部\n未通過:72%"}]
];
// 學院: 商貿外語
const collegeTradeData = [
    [{x: "108-1", y: 14, label: "商貿外語\n通過:14%"}, {x: "108-2", y: 16, label: "商貿外語\n通過:16%"}, {x: "109-1", y: 24, label: "商貿外語\n通過:24%"}],
    [{x: "108-1", y: 86, label: "商貿外語\n未通過:86%"}, {x: "108-2", y: 84, label: "商貿外語\n未通過:84%"}, {x: "109-1", y: 76, label: "商貿外語\n未通過:76%"}]
];
// 學院: 創新設計
const collegeDesignManagementData = [
    [{x: "108-1", y: 17, label: "創新設計\n通過:17%"}, {x: "108-2", y: 20, label: "創新設計\n通過:20%"}, {x: "109-1", y: 30, label: "創新設計\n通過:30%"}],
    [{x: "108-1", y: 83, label: "創新設計\n未通過:83%"}, {x: "108-2", y: 80, label: "創新設計\n未通過:80%"}, {x: "109-1", y: 70, label: "創新設計\n未通過:70%"}]
];
// 學院: 商務管理
const collegeManagementData = [
    [{x: "108-1", y: 23, label: "商務管理\n通過:23%"}, {x: "108-2", y: 29, label: "商務管理\n通過:29%"}, {x: "109-1", y: 32, label: "商務管理\n通過:32%"}],
    [{x: "108-1", y: 77, label: "商務管理\n未通過:77%"}, {x: "108-2", y: 71, label: "商務管理\n未通過:71%"}, {x: "109-1", y: 68, label: "商務管理\n未通過:68%"}]
];
// 科系: 企管
const departmentEnterpriseManagementData = [
    [{x: "108-1", y: 22, label: "企管\n通過:22%"}, {x: "108-2", y: 18, label: "企管\n通過:18%"}, {x: "109-1", y: 35, label: "企管\n通過:35%"}],
    [{x: "108-1", y: 78, label: "企管\n未通過:78%"}, {x: "108-2", y: 82, label: "企管\n未通過:82%"}, {x: "109-1", y: 65, label: "企管\n未通過:65%"}]
];
// 科系: 行管
const departmentMarketingManagementData = [
    [{x: "108-1", y: 34, label: "行管\n通過:34%"}, {x: "108-2", y: 37, label: "行管\n通過:37%"}, {x: "109-1", y: 40, label: "行管\n通過:40%"}],
    [{x: "108-1", y: 66, label: "行管\n未通過:66%"}, {x: "108-2", y: 63, label: "行管\n未通過:63%"}, {x: "109-1", y: 60, label: "行管\n未通過:60%"}]
];
// 科系: 財金
const departmentFinanceData = [
    [{x: "108-1", y: 23, label: "財金\n通過:23%"}, {x: "108-2", y: 39, label: "財金\n通過:39%"}, {x: "109-1", y: 39, label: "財金\n通過:39%"}],
    [{x: "108-1", y: 77, label: "財金\n未通過:77%"}, {x: "108-2", y: 61, label: "財金\n未通過:61%"}, {x: "109-1", y: 61, label: "財金\n未通過:61%"}]
];
// 科系: 應英
const departmentEnglishData = [
    [{x: "108-1", y: 16, label: "應英\n通過:16%"}, {x: "108-2", y: 25, label: "應英\n通過:25%"}, {x: "109-1", y: 53, label: "應英\n通過:53%"}],
    [{x: "108-1", y: 84, label: "應英\n未通過:84%"}, {x: "108-2", y: 75, label: "應英\n未通過:75%"}, {x: "109-1", y: 47, label: "應英\n未通過:47%"}]
];
// 科系: 多設
const departmentMediaDesignData = [
    [{x: "108-1", y: 17, label: "多設\n通過:17%"}, {x: "108-2", y: 21, label: "多設\n通過:21%"}, {x: "109-1", y: 34, label: "多設\n通過:34%"}],
    [{x: "108-1", y: 83, label: "多設\n未通過:83%"}, {x: "108-2", y: 79, label: "多設\n未通過:79%"}, {x: "109-1", y: 66, label: "多設\n未通過:66%"}]
];
// 科系: 會資
const departmentAccountingData = [
    [{x: "108-1", y: 18, label: "會資\n通過:18%"}, {x: "108-2", y: 20, label: "會資\n通過:20%"}, {x: "109-1", y: 17, label: "會資\n通過:17%"}],
    [{x: "108-1", y: 82, label: "會資\n未通過:82%"}, {x: "108-2", y: 80, label: "會資\n未通過:80%"}, {x: "109-1", y: 83, label: "會資\n未通過:83%"}]
];
// 科系: 資管
const departmentInformationManagementData = [
    [{x: "108-1", y: 24, label: "資管\n通過:24%"}, {x: "108-2", y: 24, label: "資管\n通過:24%"}, {x: "109-1", y: 23, label: "資管\n通過:23%"}],
    [{x: "108-1", y: 76, label: "資管\n未通過:76%"}, {x: "108-2", y: 76, label: "資管\n未通過:76%"}, {x: "109-1", y: 77, label: "資管\n未通過:77%"}]
];
// 科系: 應日
const departmentJapanData = [
    [{x: "108-1", y: 7, label: "應日\n通過:7%"}, {x: "108-2", y: 7, label: "應日\n通過:7%"}, {x: "109-1", y: 25, label: "應日\n通過:25%"}],
    [{x: "108-1", y: 93, label: "應日\n未通過:93%"}, {x: "108-2", y: 93, label: "應日\n未通過:93%"}, {x: "109-1", y: 75, label: "應日\n未通過:75%"}]
];
// 科系: 財金
const departmentEntertainmentData = [
    [{x: "108-1", y: 10, label: "財金\n通過:10%"}, {x: "108-2", y: 39, label: "財金\n通過:39%"}, {x: "109-1", y: 39, label: "財金\n通過:39%"}],
    [{x: "108-1", y: 90, label: "財金\n未通過:90%"}, {x: "108-2", y: 61, label: "財金\n未通過:61%"}, {x: "109-1", y: 61, label: "財金\n未通過:61%"}]
];
// 科系: 商管
const departmentBusinessManagementData = [
    [{x: "108-1", y: 4, label: "商管\n通過:4%"}, {x: "108-2", y: 11, label: "商管\n通過:11%"}, {x: "109-1", y: 12, label: "商管\n通過:12%"}],
    [{x: "108-1", y: 96, label: "商管\n未通過:96%"}, {x: "108-2", y: 89, label: "商管\n未通過:89%"}, {x: "109-1", y: 88, label: "商管\n未通過:88%"}]
];
// 科系: 國貿
const departmentInternationalTradingData = [
    [{x: "108-1", y: 21, label: "國貿\n通過:21%"}, {x: "108-2", y: 17, label: "國貿\n通過:17%"}, {x: "109-1", y: 22, label: "國貿\n通過:22%"}],
    [{x: "108-1", y: 79, label: "國貿\n未通過:79%"}, {x: "108-2", y: 83, label: "國貿\n未通過:83%"}, {x: "109-1", y: 78, label: "國貿\n未通過:78%"}]
];
// 年級: 一年級
const gradeOneData = [
    [{x: "108-1", y: 4, label: "一年級\n通過:4%"}, {x: "108-2", y: 11, label: "一年級\n通過:11%"}, {x: "109-1", y: 5, label: "一年級\n通過:5%"}],
    [{x: "108-1", y: 96, label: "一年級\n未通過:96%"}, {x: "108-2", y: 89, label: "一年級\n未通過:89%"}, {x: "109-1", y: 95, label: "一年級\n未通過:95%"}]
];
// 年級: 二年級
const gradeTwoData = [
    [{x: "108-1", y: 39, label: "二年級\n通過:39%"}, {x: "108-2", y: 29, label: "二年級\n通過:29%"}, {x: "109-1", y: 34, label: "二年級\n通過:34%"}],
    [{x: "108-1", y: 61, label: "二年級\n未通過:61%"}, {x: "108-2", y: 71, label: "二年級\n未通過:71%"}, {x: "109-1", y: 66, label: "二年級\n未通過:66%"}]
];
// 年級: 三年級
const gradeThreeData = [
    [{x: "108-1", y: 54, label: "三年級\n通過:54%"}, {x: "108-2", y: 34, label: "三年級\n通過:34%"}, {x: "109-1", y: 52, label: "三年級\n通過:52%"}],
    [{x: "108-1", y: 46, label: "三年級\n未通過:46%"}, {x: "108-2", y: 66, label: "三年級\n未通過:66%"}, {x: "109-1", y: 48, label: "三年級\n未通過:48%"}]
];
// 年級: 四年級
const gradeFourData = [
    [{x: "108-1", y: 42, label: "四年級\n通過:42%"}, {x: "108-2", y: 33, label: "四年級\n通過:33%"}, {x: "109-1", y: 49, label: "四年級\n通過:49%"}],
    [{x: "108-1", y: 58, label: "四年級\n未通過:58%"}, {x: "108-2", y: 67, label: "四年級\n未通過:67%"}, {x: "109-1", y: 51, label: "四年級\n未通過:51%"}]
];
// 年級: 五年級
const gradeFiveData = [
    [{x: "108-1", y: 56, label: "四年級\n通過:56%"}, {x: "108-2", y: 0, label: "四年級\n通過:0%"}, {x: "109-1", y: 33, label: "四年級\n通過:33%"}],
    [{x: "108-1", y: 44, label: "四年級\n未通過:44%"}, {x: "108-2", y: 100, label: "四年級\n未通過:100%"}, {x: "109-1", y: 67, label: "四年級\n未通過:67%"}]
];

class Past extends React.Component {

    transformPercentageData(dataset) {
        const totals = dataset[0].map((data, i) => {
          return dataset.reduce((memo, curr) => {
            return memo + curr[i].y;
          }, 0);
        });
        return dataset.map((data) => {
          return data.map((datum, i) => {
            return { x: datum.x, y: (datum.y / totals[i]) * 100 };
          });
        });
    }

    render(){
        //const renderGenderMaleData = this.transformPercentageData(genderMaleData);
        //const renderGenderFemaleData = this.transformPercentageData(genderFemaleData);
        //const renderSourceDisabledData = this.transformPercentageData(sourceDisabledData);
        //const renderSourceSpecialData = this.transformPercentageData(sourceSpecialData);
        //const renderSourceStarData = this.transformPercentageData(sourceStarData);
        //const renderSourceSelectionData = this.transformPercentageData(sourceSelectionData);
        //const renderSourceRegisterData = this.transformPercentageData(sourceRegisterData);
        //const renderSourceSkillData = this.transformPercentageData(sourceSkillData);
        //const renderSourceApplyData = this.transformPercentageData(sourceApplyData);
        //const renderSourceAssessmentData = this.transformPercentageData(sourceAssessmentData);
        //const renderSourceTransferData = this.transformPercentageData(sourceTransferData);
        //const renderSourceAdmissionData = this.transformPercentageData(sourceAdmissionData);
        //const renderSourceFreeData = this.transformPercentageData(sourceFreeData);
        return (
            <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <ul id="title-spacer" className="breadcrumbs">
                            <li><span>歷年完課分析</span></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>性別完課分析</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">性別完課分析</Popover.Header>
                                                <Popover.Body>
                                                分析歷年男性與女性的完課狀況
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <VictoryChart
                                domainPadding={{x: [60,60], y: 0}}
                            >
                                <VictoryGroup
                                    offset={8}
                                    style={{ data: { width: 6 } }}
                                >
                                    <VictoryStack
                                        colorScale={["green","red"]}
                                    >
                                        {genderMaleData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["green","red"]}
                                    >
                                        {genderFemaleData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                </VictoryGroup>
                                <VictoryAxis dependentAxis
                                    tickFormat={(tick) => `${tick}%`}
                                    crossAxis={false}
                                />
                                <VictoryAxis
                                    tickFormat={["108-1", "108-2", "109-1"]}
                                />
                            </VictoryChart>
                        </div>
                    </div>
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>入學管道完課分析</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">入學管道完課分析</Popover.Header>
                                                <Popover.Body>
                                                分析歷年不同入學管道的學生的完課狀況
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <VictoryChart
                                //theme={VictoryTheme.material}
                                domainPadding={{x: [60,60], y: 0}}
                            >
                                <VictoryGroup
                                    offset={8}
                                    style={{ data: { width: 6 } }}
                                >
                                    <VictoryStack
                                        colorScale={["#F1F8E9","#FFEBEE"]}
                                    >
                                        {sourceDisabledData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#DCEDC8","#FFCDD2"]}
                                    >
                                        {sourceSpecialData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#C5E1A5","#EF9A9A"]}
                                    >
                                        {sourceStarData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#AED581","#E57373"]}
                                    >
                                        {sourceSelectionData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#9CCC65","#F44336"]}
                                    >
                                        {sourceRegisterData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#8BC34A","#E53935"]}
                                    >
                                        {sourceSkillData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#7CB342","#D32F2F"]}
                                    >
                                        {sourceApplyData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#689F38","#C62828"]}
                                    >
                                        {sourceAssessmentData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#558B2F","#B71C1C"]}
                                    >
                                        {sourceTransferData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#33691E","#6C0000"]}
                                    >
                                        {sourceAdmissionData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#1B5E20","#880E4F"]}
                                    >
                                        {sourceFreeData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                </VictoryGroup>
                                <VictoryAxis dependentAxis
                                    tickFormat={(tick) => `${tick}%`}
                                    crossAxis={false}
                                />
                                <VictoryAxis
                                    tickFormat={["108-1", "108-2", "109-1"]}
                                />
                            </VictoryChart>
                        </div>
                    </div>
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>學制完課分析</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">學制完課分析</Popover.Header>
                                                <Popover.Body>
                                                分析歷年不同學制的學生的完課狀況
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <VictoryChart
                                domainPadding={{x: [60,60], y: 0}}
                                //theme={VictoryTheme.material}
                            >
                                <VictoryGroup
                                    offset={10}
                                    style={{ data: { width: 6 } }}
                                >
                                    <VictoryStack
                                        colorScale={["#8BC34A","#E53935"]}
                                    >
                                        {systemDayData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#7CB342","#D32F2F"]}
                                    >
                                        {systemNightData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                </VictoryGroup>
                                <VictoryAxis dependentAxis
                                    tickFormat={(tick) => `${tick}%`}
                                    crossAxis={false}
                                />
                                <VictoryAxis
                                    tickFormat={["108-1", "108-2", "109-1"]}
                                />
                            </VictoryChart>
                        </div>
                    </div>
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>學院完課分析</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">學院完課分析</Popover.Header>
                                                <Popover.Body>
                                                分析歷年不同學院的學生的完課狀況
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <VictoryChart
                                domainPadding={{x: [60,60], y: 0}}
                                //theme={VictoryTheme.material}
                            >
                                <VictoryGroup
                                    offset={10}
                                    style={{ data: { width: 6 } }}
                                >
                                    <VictoryStack
                                        colorScale={["#F1F8E9","#FFEBEE"]}
                                    >
                                        {collegeTradeData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#DCEDC8","#FFCDD2"]}
                                    >
                                        {collegeDesignManagementData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#C5E1A5","#EF9A9A"]}
                                    >
                                        {collegeManagementData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                </VictoryGroup>
                                <VictoryAxis dependentAxis
                                    tickFormat={(tick) => `${tick}%`}
                                    crossAxis={false}
                                />
                                <VictoryAxis
                                    tickFormat={["108-1", "108-2", "109-1"]}
                                />
                            </VictoryChart>
                        </div>
                    </div>
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>科系完課分析</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">科系完課分析</Popover.Header>
                                                <Popover.Body>
                                                分析歷年不同科系的學生的完課狀況
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <VictoryChart
                                //theme={VictoryTheme.material}
                                domainPadding={{x: [60,60], y: 0}}
                            >
                                <VictoryGroup
                                    offset={8}
                                    style={{ data: { width: 6 } }}
                                >
                                    <VictoryStack
                                        colorScale={["#F1F8E9","#FFEBEE"]}
                                    >
                                        {departmentAccountingData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#DCEDC8","#FFCDD2"]}
                                    >
                                        {departmentBusinessManagementData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#C5E1A5","#EF9A9A"]}
                                    >
                                        {departmentEnglishData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#AED581","#E57373"]}
                                    >
                                        {departmentEnterpriseManagementData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#9CCC65","#F44336"]}
                                    >
                                        {departmentEntertainmentData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#8BC34A","#E53935"]}
                                    >
                                        {departmentFinanceData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#7CB342","#D32F2F"]}
                                    >
                                        {departmentInformationManagementData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#689F38","#C62828"]}
                                    >
                                        {departmentInternationalTradingData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#558B2F","#B71C1C"]}
                                    >
                                        {departmentJapanData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#33691E","#6C0000"]}
                                    >
                                        {departmentMarketingManagementData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#1B5E20","#880E4F"]}
                                    >
                                        {departmentMediaDesignData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                </VictoryGroup>
                                <VictoryAxis dependentAxis
                                    tickFormat={(tick) => `${tick}%`}
                                    crossAxis={false}
                                />
                                <VictoryAxis
                                    tickFormat={["108-1", "108-2", "109-1"]}
                                />
                            </VictoryChart>
                        </div>
                    </div>
                    <div className="col-md-12 pt-3">
                        <div className="bg-body rounded shadow-sm px-3">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                <h4>年級完課分析</h4>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="mt-2" style={{marginRight: '.5em', cursor: 'pointer'}}>
                                        <OverlayTrigger rootClose trigger="click" placement="top" overlay={(
                                            <Popover>
                                                <Popover.Header as="h3">年級完課分析</Popover.Header>
                                                <Popover.Body>
                                                分析歷年不同年級的學生的完課狀況
                                                </Popover.Body>
                                            </Popover>
                                        )}>
                                            <i className="fa fa-question-circle-o fa-lg" aria-hidden="true"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <VictoryChart
                                domainPadding={{x: [60,60], y: 0}}
                                //theme={VictoryTheme.material}
                            >
                                <VictoryGroup
                                    offset={10}
                                    style={{ data: { width: 6 } }}
                                >
                                    <VictoryStack
                                        colorScale={["#F1F8E9","#FFEBEE"]}
                                    >
                                        {gradeOneData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#DCEDC8","#FFCDD2"]}
                                    >
                                        {gradeTwoData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#C5E1A5","#EF9A9A"]}
                                    >
                                        {gradeThreeData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#AED581","#E57373"]}
                                    >
                                        {gradeFourData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                    <VictoryStack
                                        colorScale={["#9CCC65","#F44336"]}
                                    >
                                        {gradeFiveData.map((data, i) => {
                                            return <VictoryBar data={data} key={i} labelComponent={<VictoryTooltip flyoutPadding={10} style={{fontSize: '6px'}} />} />;
                                        })}
                                    </VictoryStack>
                                </VictoryGroup>
                                <VictoryAxis dependentAxis
                                    tickFormat={(tick) => `${tick}%`}
                                    crossAxis={false}
                                />
                                <VictoryAxis
                                    tickFormat={["108-1", "108-2", "109-1"]}
                                />
                            </VictoryChart>
                        </div>
                    </div>
                </div>
            </main>
        );
    }

}

export default Past;