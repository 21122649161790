import React from 'react';


interface CURProps {
    unitName: string;
    unitType: string;
    unitWeek: number;
    videoDuration: string;
    index: number;
    onInputChangeHandler: any;
    onSelectChangeHandler: any;
}

class CourseUnitRow extends React.Component<CURProps>{

    onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onInputChangeHandler(e.target.name, e.target.value, this.props.index);
    }

    onSelectChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.onSelectChangeHandler(e.target.name, e.target.value, this.props.index);
    }

    render() {
        return (
            <>
                <tr>
                    <td><input type="text" name="unitName" value={this.props.unitName} onChange={this.onInputChangeHandler} /></td>
                    <td><input type="text" name="videoDuration" value={this.props.videoDuration} onChange={this.onInputChangeHandler} /></td>
                    <td>
                        <select name="unitType" value={this.props.unitType} onChange={this.onSelectChangeHandler}>
                            <option value="影片">影片</option>
                            <option value="討論">討論</option>
                            <option value="單元測驗">單元測驗</option>
                            <option value="作業">課程作業</option>
                            <option value="後測">後測</option>
                            <option value="問卷">問卷</option>
                            <option value="前測">前測</option>
                            <option value="同步線上課程">同步線上課程</option>
                            <option value="專題">專題</option>
                        </select>
                    </td>
                    <td>
                        <select name="unitWeek" value={this.props.unitWeek} onChange={this.onSelectChangeHandler}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                        </select>
                    </td>
                </tr>
            </>
        )
    }

}

export default CourseUnitRow;