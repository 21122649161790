import React from 'react';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import OverviewService from '../../services/overviewService';
import { LoginContext } from '../../loginContext';
import StudentOverviewTable from './studentOverviewTable';

interface OProps { }
interface OState {
    role: string;
    sdate: string;
    edate: string;
    sDateDisabled: boolean;
    eDateDisabled: boolean;
    periodDate: string;
    progressResult: any;
    lackVideoList: any;
    loading: boolean;
    modal_show: boolean;
}

class StudentOverview extends React.Component<OProps, OState>{

    private overviewService: OverviewService;
    private sdate: string;
    private edate: string;
    private date: string;
    private account:string;
    private org: string;
    static contextType = LoginContext;

    constructor(props: any){
        super(props);
        this.overviewService = new OverviewService();
        this.state = {
            role: "",
            sdate: '2023-03-08',
            edate: '2023-05-10',
            sDateDisabled: true,
            eDateDisabled: true,
            periodDate: '2023-03-08/2023-05-10',
            progressResult: {
                courseList: [],
                courseIdList: [],
                courseInfoAndUnits: [],
                quizList: [],
                videoList: []
            },
            lackVideoList: [],
            loading: false,
            modal_show: false
        };
        this.org = "";
        this.account = "";
        this.sdate = this.state.sdate;
        this.edate = this.state.edate;
        this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
    }

    componentDidMount(){
        this.setState({ role: this.context.role });
        this.org = this.context.org;
        this.account = window.btoa(this.context.account);
        this.getStudentCourseProgress(this.date, this.org, this.account, this.context.role);
    }

    getStudentCourseProgress = async (date: string, org: string, filter: string, role: string) => {
        this.setState({ loading: true });
        let progressResult = await this.overviewService.getStudentCourseProgress(date, org, filter, role, undefined);
        this.setState({ progressResult: progressResult, loading: false });
    }

    handleSdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sdate: e.target.value })
    }

    handleEdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ edate: e.target.value })
    }

    periodDateHandler = (e: any) => {
        const targetPeriod = e.target.value;
        if(targetPeriod){
            const periodStartDate = targetPeriod.split('/')[0];
            const periodEndDate = targetPeriod.split('/')[1];
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: true,
                eDateDisabled: true,
                sdate: periodStartDate,
                edate: periodEndDate
            });
        }else{
            this.setState({
                periodDate: e.target.value,
                sDateDisabled: false,
                eDateDisabled: false
            });
        }
    }

    submitDateRange = () => {
        if (moment(this.state.edate).diff(this.state.sdate) < 0) {
            alert("結束日期需大於起始日期")
        } else if (moment(this.state.edate).diff(this.state.sdate, 'days') > 200) {
            alert("日期區間請在六個月內")
        } else {
            //this.resetState();
            this.sdate = this.state.sdate;
            this.edate = this.state.edate;
            this.date = this.sdate.replace(/-/g, '') + this.edate.replace(/-/g, '');
            this.getStudentCourseProgress(this.date, this.org, this.account, this.context.role);
        }
    }

    renderQuestionnaireScore = (courseName: string, questionnaireScore: number, units: any, questionnaireList: any) => {
        if(questionnaireScore !== 0){
            const questionnaireUnits = units.filter((unit: any) => unit.unitType === "問卷");
            const unitScore = questionnaireScore/questionnaireUnits.length;
            let totalQuestionnaireScore = 0;
            let lackQuestionnaireList: any = [];
            for(let i=0; i<questionnaireUnits.length; i++){
                let lackQuestionnaireObj = {
                    name: questionnaireUnits[i].unitName,
                    complete: false
                }
                for(let j=0;j<questionnaireList.length;j++){
                    if(questionnaireList[j].courseName === courseName && questionnaireList[j].name === questionnaireUnits[i].unitName){
                        totalQuestionnaireScore += unitScore;
                        lackQuestionnaireObj.complete = true;
                    }
                }
                lackQuestionnaireList.push(lackQuestionnaireObj);
            }
            return totalQuestionnaireScore >= questionnaireScore ? questionnaireScore:<StudentOverviewTable contentType="questionnaire" score={totalQuestionnaireScore} progressResult={lackQuestionnaireList} />;
        }else{
            return "-";
        }
    }

    renderPreTestScore = (courseName: string, preTestScore: number, units: any, quizList: any) => {
        if(preTestScore !== 0){
            const testUnits = units.filter((unit: any) => unit.unitType === "前測");
            let lackPreTestList = [];
            let currentTestScore = 0;
            for(let i=0; i<testUnits.length; i++){
                let testUnitName = testUnits[i].unitName;
                let lackPreTestObj: any = {
                    name: testUnitName,
                    complete: false
                }
                for(let j=0; j<quizList.length; j++){
                    if(quizList[j].courseName === courseName && quizList[j].name === testUnitName){
                        lackPreTestObj.complete = true;
                        currentTestScore = preTestScore;
                    }
                }
                lackPreTestList.push(lackPreTestObj);
            }
            
            currentTestScore = parseFloat(currentTestScore.toFixed(2));
            return currentTestScore >= preTestScore ? preTestScore:<StudentOverviewTable contentType="preTest" score={currentTestScore} progressResult={lackPreTestList} />;
        }else{
            return "-";
        }
    }

    renderTestScore = (courseName: string, testScore: number, units: any, quizList: any) => {
        if(testScore !== 0){
            const testUnits = units.filter((unit: any) => unit.unitType === "單元測驗");
            let unitScore = testScore/testUnits.length;
            let currentTestScore = 0;
            let lackTestList = [];
            for(let i=0; i<testUnits.length; i++){
                let testUnitName = testUnits[i].unitName;
                let lackTestObj: any = {
                    name: testUnitName,
                    complete: false
                }
                let testDo = false;
                for(let j=0; j<quizList.length; j++){
                    if(quizList[j].courseName === courseName && quizList[j].name === testUnitName){
                        currentTestScore += (unitScore*quizList[j].score/100);
                        testDo = true;
                        lackTestObj.result = quizList[j].score;
                        lackTestObj.complete = true;
                    }
                }
                if(!testDo){
                    lackTestObj.result = "-";
                }
                lackTestList.push(lackTestObj);
            }
            currentTestScore = parseFloat(currentTestScore.toFixed(2));
            return currentTestScore >= testScore ? currentTestScore:<StudentOverviewTable contentType="test" score={currentTestScore} progressResult={lackTestList} />;
        }else{
            return "-";
        }
    }

    renderPostTestScore = (courseName: string, postTestScore: number, units: any, quizList: any) => {
        if(postTestScore !== 0){
            const testUnits = units.filter((unit: any) => unit.unitType === "後測");
            let userPostTestScore = 0;
            let lackPostTestList = [];
            for(let i=0; i<testUnits.length; i++){
                let testUnitName = testUnits[i].unitName;
                let lackPostTestObj: any = {
                    name: testUnitName,
                    complete: false
                }
                for(let j=0; j<quizList.length; j++){
                    if(quizList[j].courseName === courseName && (quizList[j].name.indexOf("後測") !== -1 || quizList[j].name.indexOf("總測驗") !== -1)){
                        userPostTestScore = postTestScore;
                        lackPostTestObj.complete = true;
                    }
                }
                lackPostTestList.push(lackPostTestObj);
            }
            
            userPostTestScore = parseFloat(userPostTestScore.toFixed(2));
            return userPostTestScore >= postTestScore ? postTestScore:<StudentOverviewTable contentType="postTest" score={userPostTestScore} progressResult={lackPostTestList} />;
        }else{
            return "-";
        }
    }

    renderVideoScore = (targetCourseId: string, videoScore: number, units: any, videoResultList: any) => {
        if(videoScore !== 0){
            const videoUnits = units.filter((unit: any) => unit.unitType === "影片");
            const targetVideoList = videoResultList.filter((videoResult: any) => videoResult._id === ("https://moocs.chihlee.edu.tw/info/" + targetCourseId));
            
            let totalVideoScore = 0;
            let unitVideoScore = videoScore/videoUnits.length;
            let lackVideoList = [];
            for(let i=0;i<videoUnits.length;i++){
                const videoUnitName = videoUnits[i].unitName;
                const videoDurationList = videoUnits[i].videoDuration.split(":");
                const videoUnitLength = parseInt(videoDurationList[1])*60 + parseInt(videoDurationList[2]);
                let lackVideoObj: any = {
                    name: videoUnitName,
                    durations: videoUnitLength,
                    result: 0,
                    complete: false
                };
                if(targetVideoList.length !== 0){
                    const targetVideoUnits = targetVideoList[0].units;
                    for(let j=0;j<targetVideoUnits.length;j++){
                        const targetVideoUnitName = targetVideoUnits[j].unitName;
                        if(videoUnitName === targetVideoUnitName){
                            lackVideoObj.result = targetVideoUnits[j].watchDurations;
                            if(targetVideoUnits[j].watchDurations >= videoUnitLength){
                                totalVideoScore += unitVideoScore;
                                lackVideoObj.complete = true;
                            }
                        }
                    }
                }
                lackVideoList.push(lackVideoObj);
            }
            totalVideoScore = parseFloat(totalVideoScore.toFixed(2));
            return totalVideoScore >= videoScore ? videoScore:<StudentOverviewTable contentType="video" score={totalVideoScore} progressResult={lackVideoList} />;
        }else{
            return "-";
        }
    }

    renderHomeworkScore = (targetCourseId: string, homeworkScore: number, units: any, homeworkResultList: any) => {
        if(homeworkScore !== 0){
            const homeworkUnits = units.filter((unit: any) => unit.unitType === "作業");
            const targetHomeworkList = homeworkResultList.filter((homeworkResult: any) => homeworkResult._id.courseId === ("https://moocs.chihlee.edu.tw/info/" + targetCourseId));
            let totalHomeworkScore = 0;
            const unitHomeworkScore = homeworkScore/homeworkUnits.length;
            let lackHomeworkList = [];
            for(let i=0; i<homeworkUnits.length;i++){
                let lackHomeworkObj = {
                    name: homeworkUnits[i].unitName,
                    submit: 0,
                    score: -1,
                    complete: false
                };
                for(let j=0;j<targetHomeworkList.length;j++){
                    if(homeworkUnits[i].unitName === targetHomeworkList[j].homework_name){
                        lackHomeworkObj.submit = targetHomeworkList[j].submit;
                        lackHomeworkObj.score = targetHomeworkList[j].score;
                        if(targetHomeworkList[j].score !== -1){
                            const addScore = unitHomeworkScore*targetHomeworkList[j].score/100;
                            totalHomeworkScore += addScore;
                        }
                    }
                }
                lackHomeworkObj.score = totalHomeworkScore;
                lackHomeworkList.push(lackHomeworkObj);
            }
            return totalHomeworkScore >= homeworkScore ? homeworkScore:<StudentOverviewTable contentType="homework" score={totalHomeworkScore} progressResult={lackHomeworkList} />;
        }else{
            return "-";
        }
    }

    renderDiscussionScore = (targetCourseId: string, discussionScore: number, units: any, discussionResultList: any) => {
        if(discussionScore !== 0){
            const discussionUnits = units.filter((unit: any) => unit.unitType === "討論");
            let totalDiscussionScore = 0;
            let lackDiscussionList = [];
            const unitDiscussionScore = discussionScore/discussionUnits.length;
            const targetDiscussionList = discussionResultList.filter((discussionResult: any) => discussionResult._id.courseId === ("https://moocs.chihlee.edu.tw/info/" + targetCourseId));
            for(let i=0; i<discussionUnits.length;i++){
                let lackDiscussionObj = {
                    name: discussionUnits[i].unitName,
                    complete: false
                };
                for(let j=0;j<targetDiscussionList.length;j++){
                    if(discussionUnits[i].unitName === targetDiscussionList[j].unitName){
                        if(!lackDiscussionObj.complete){
                            lackDiscussionObj.complete = true;
                            totalDiscussionScore += unitDiscussionScore;
                        }
                    }
                }
                lackDiscussionList.push(lackDiscussionObj);
            }
            return totalDiscussionScore >= discussionScore ? discussionScore:<StudentOverviewTable contentType="discussion" score={totalDiscussionScore} progressResult={lackDiscussionList} />;
        }else{
            return "-";
        }
    }

    renderSyncCourseScore = (targetCourseId: string, syncCourseScore: number, units: any, courseAccessResultList: any) => {
        if(syncCourseScore !== 0){
            const syncCourseUnits = units.filter((unit: any) => unit.unitType === "同步線上課程");
            let totalSyncCourseScore = 0;
            let lackSyncCourseList = [];
            const unitSyncCourseScore = syncCourseScore/syncCourseUnits.length;
            const targetCourseAccessList = courseAccessResultList.filter((courseAccessResult: any) => courseAccessResult._id.courseId === ("https://moocs.chihlee.edu.tw/info/" + targetCourseId));
            for(let i=0; i<syncCourseUnits.length;i++){
                let lackSyncCourseObj = {
                    name: syncCourseUnits[i].unitName,
                    complete: false
                };
                for(let j=0;j<targetCourseAccessList.length;j++){
                    if(syncCourseUnits[i].unitName === targetCourseAccessList[j].unitName){
                        if(!lackSyncCourseObj.complete){
                            lackSyncCourseObj.complete = true;
                            totalSyncCourseScore += unitSyncCourseScore;
                        }
                    }
                }
                lackSyncCourseList.push(lackSyncCourseObj);
            }
            return totalSyncCourseScore >= syncCourseScore ? syncCourseScore:<StudentOverviewTable contentType="syncCourse" score={totalSyncCourseScore} progressResult={lackSyncCourseList} />;
        }else{
            return "-";
        }
    }

    handleClose = () => {
        this.setState({
            modal_show: false
        });
    }

    render(){
        return (
            <>
                <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row mt-4">
                        <div className="col-md-3 py-1">
                            <h5>學期磨課師</h5>
                            <Form.Select value={this.state.periodDate} onChange={this.periodDateHandler}>
                                <option value="2022-09-14/2022-11-30">111-1 學期磨課師</option>
                                <option value="2022-02-23/2022-05-18">110-2 學期磨課師</option>
                                {/*
                                <option value="2021-08-01/2022-01-31">110-第一學期</option>
                                <option value="2021-02-01/2021-07-31">109-第二學期</option>
                                <option value="2020-08-01/2021-01-31">109-第一學期</option>
                                */}
                            </Form.Select>
                        </div>
                        <div className="col-md-5 py-1">
                            <h5>時間</h5>
                            <div className="row">
                                <div className="col-auto">
                                    <div className="input-group">
                                        <input type="date" className="form-control date" name="sdate" value={this.state.sdate} onChange={this.handleSdate} disabled={this.state.sDateDisabled} />
                                    </div>
                                </div>
                                <div className="col-auto py-2 m-none lh-lg">~</div>
                                <div className="col-auto">
                                    <div className="input-group">
                                        <input type="date" className="form-control date" name="edate" value={this.state.edate} onChange={this.handleEdate} disabled={this.state.eDateDisabled} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 py-1">
                            <h5>動作</h5>
                            <button className="btn btn-secondary" onClick={this.submitDateRange}>篩選</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 pt-3">
                            <div className="bg-body rounded shadow-sm px-3">
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                                    <h4>學習進度 ({this.state.periodDate}) <span style={{color: 'red', fontSize: '12px'}}>* 進度每天統計一次，只算到前一天喔!</span></h4>
                                </div>
                                {this.state.loading && <div className="d-flex justify-content-center"><div className="spinner-border text-primary m-5" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div></div>}
                                {!this.state.loading && this.state.progressResult && this.state.progressResult.courseInfoAndUnits && this.state.progressResult.courseInfoAndUnits.length !==0 ? this.state.progressResult.courseInfoAndUnits.map((course: any) => {
                                    return (
                                        <>
                                            <h5 className="m-2 pb-4">{course.courseName}</h5>
                                            <table className="table mb-6 align-middle">
                                                <thead>
                                                    <tr>
                                                        <th>課程結業標準</th>
                                                        <th style={{textAlign:'center'}}>問卷(課前與課後)</th>
                                                        <th style={{textAlign:'center'}}>前測</th>
                                                        <th style={{textAlign:'center'}}>單元測驗</th>
                                                        <th style={{textAlign:'center'}}>教材閱讀</th>
                                                        <th style={{textAlign:'center'}}>總測驗</th>
                                                        <th style={{textAlign:'center'}}>討論</th>
                                                        <th style={{textAlign:'center'}}>作業</th>
                                                        <th style={{textAlign:'center'}}>同步線上課程</th>
                                                        <th style={{textAlign:'center'}}>通過分數</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>結業標準分數</th>
                                                        <th style={{textAlign:'center'}}>{course.questionnaire? course.questionnaire*100:"-"}</th>
                                                        <th style={{textAlign:'center'}}>{course.preTest? course.preTest*100:"-"}</th>
                                                        <th style={{textAlign:'center'}}>{course.test? course.test*100:"-"}</th>
                                                        <th style={{textAlign:'center'}}>{course.videoDuration? course.videoDuration*100:"-"}</th>
                                                        <th style={{textAlign:'center'}}>{course.postTest? course.postTest*100:"-"}</th>
                                                        <th style={{textAlign:'center'}}>{course.discussion? course.discussion*100:"-"}</th>
                                                        <th style={{textAlign:'center'}}>{course.homework? course.homework*100:"-"}</th>
                                                        <th style={{textAlign:'center'}}>{course.syncCourse? course.syncCourse*100:"-"}</th>
                                                        <td style={{textAlign:'center'}}>60</td>
                                                    </tr>
                                                    <tr>
                                                        <td>您目前的分數</td>
                                                        {/* 問卷(課前與課後) */}<td style={{textAlign:'center'}}>{course.questionnaire? this.renderQuestionnaireScore(course.courseName, course.questionnaire*100, course.units[0].units, this.state.progressResult.questionnaireList):"-"}</td>
                                                        {/* 前測 */}<td style={{textAlign:'center'}}>{course.preTest? this.renderPreTestScore(course.courseName, course.preTest*100, course.units[0].units, this.state.progressResult.quizList):"-"}</td>
                                                        {/* 單元測驗 */}<td style={{textAlign:'center'}}>{course.test? this.renderTestScore(course.courseName, course.test*100, course.units[0].units, this.state.progressResult.quizList):"-"}</td>
                                                        {/* 教材閱讀 */}<td style={{textAlign:'center'}}>{course.videoDuration? this.renderVideoScore(course.courseId, course.videoDuration*100, course.units[0].units, this.state.progressResult.videoList):"-"}</td>
                                                        {/* 總測驗 */}<td style={{textAlign:'center'}}>{course.postTest? this.renderPostTestScore(course.courseName, course.postTest*100, course.units[0].units, this.state.progressResult.quizList):"-"}</td>
                                                        {/* 討論 */}<td style={{textAlign:'center'}}>{course.discussion? this.renderDiscussionScore(course.courseId, course.discussion*100, course.units[0].units, this.state.progressResult.discussionList):"-"}</td>
                                                        {/* 作業 */}<td style={{textAlign:'center'}}>{course.homework? this.renderHomeworkScore(course.courseId, course.homework*100, course.units[0].units, this.state.progressResult.homeworkList):"-"}</td>
                                                        {/* 同步線上課程 */}<td style={{textAlign:'center'}}>{course.syncCourse? this.renderSyncCourseScore(course.courseId, course.syncCourse*100, course.units[0].units, this.state.progressResult.courseAccessList):"-"}</td>
                                                        {/* 通過分數 */}<td style={{textAlign:'center'}}>-</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                    )
                                }):<h5 className="text-center pt-2 pb-2">您目前沒有參加任何課程</h5>}
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }

}

export default StudentOverview;